/* Adobe Garamond Pro */
@font-face {
  font-family: "Adobe Garamond Pro";
  src: url("AGaramondPro-Regular.woff2") format("woff2"),
    url("AGaramondPro-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Adobe Garamond Pro";
  src: url("AGaramondPro-Italic.woff2") format("woff2"),
    url("AGaramondPro-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Adobe Garamond Pro";
  src: url("AGaramondPro-Bold.woff2") format("woff2"),
    url("AGaramondPro-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Adobe Garamond Pro";
  src: url("AGaramondPro-BoldItalic.woff2") format("woff2"),
    url("AGaramondPro-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

/* Gotham */
@font-face {
  font-family: "Gotham";
  src: url("Gotham-Thin.woff2") format("woff2"),
    url("Gotham-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-ThinItalic.woff2") format("woff2"),
    url("Gotham-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-Light.woff2") format("woff2"),
    url("Gotham-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-LightItalic.woff2") format("woff2"),
    url("Gotham-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-Book.woff2") format("woff2"),
    url("Gotham-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-BookItalic.woff2") format("woff2"),
    url("Gotham-BookItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-Medium.woff2") format("woff2"),
    url("Gotham-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-MediumItalic.woff2") format("woff2"),
    url("Gotham-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-Bold.woff2") format("woff2"),
    url("Gotham-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-BoldItalic.woff2") format("woff2"),
    url("Gotham-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url("Gotham-Black.woff2") format("woff2"),
    url("Gotham-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* Trajan Pro */
@font-face {
  font-family: "Trajan Pro";
  src: url("TrajanPro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Trajan Pro";
  src: url("TrajanPro-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* AvertaStd */
@font-face {
  font-family: "AvertaStd";
  src: url("AvertaStd-Thin.woff2") format("woff2"),
    url("AvertaStd-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvertaStd";
  src: url("AvertaStd-ThinItalic.woff2") format("woff2"),
    url("AvertaStd-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AvertaStd";
  src: url("AvertaStd-Light.woff2") format("woff2"),
    url("AvertaStd-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvertaStd";
  src: url("AvertaStd-LightItalic.woff2") format("woff2"),
    url("AvertaStd-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AvertaStd";
  src: url("AvertaStd-Regular.woff2") format("woff2"),
    url("AvertaStd-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvertaStd";
  src: url("AvertaStd-RegularItalic.woff2") format("woff2"),
    url("AvertaStd-RegularItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AvertaStd";
  src: url("AvertaStd-Semibold.woff2") format("woff2"),
    url("AvertaStd-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvertaStd";
  src: url("AvertaStd-SemiboldItalic.woff2") format("woff2"),
    url("AvertaStd-SemiboldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AvertaStd";
  src: url("AvertaStd-Bold.woff2") format("woff2"),
    url("AvertaStd-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvertaStd";
  src: url("AvertaStd-BoldItalic.woff2") format("woff2"),
    url("AvertaStd-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AvertaStd";
  src: url("AvertaStd-ExtraBold.woff2") format("woff2"),
    url("AvertaStd-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvertaStd";
  src: url("AvertaStd-ExtraBoldItalic.woff2") format("woff2"),
    url("AvertaStd-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "AvertaStd";
  src: url("AvertaStd-Black.woff2") format("woff2"),
    url("AvertaStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvertaStd";
  src: url("AvertaStd-BlackItalic.woff2") format("woff2"),
    url("AvertaStd-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
