:root {
  --main-font-size: 18px;
  --main-line-height: 26px;
  --font: AvertaStd, Arial, sans-serif;
  --adobe: "Adobe Garamond Pro";
  --bebas: "Bebas Neue";
  --primary: #20a757;
  --secondary: #0c54a0;
  --blue: #163d68;
  --white: #fff;
  --black: #000;
  --grey: #f1f2f2;
  --smallFont: 13px;
  --midFont: 15px;
  --smallHeight: 20px;
  --letter3: 3px;
  --letter2: 2px;
  --uppercase: uppercase;
  --letter1: 1px;
  --black70: rgb(0 0 0 / 70%);
}

body {
  color: var(--black70);
  font-family: var(--font);
  font-size: var(--main-font-size);
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: var(--main-line-height);
  margin: 0;
  outline: 0;
  overflow-x: hidden;
  padding: 0;
}

* {
  outline: 0;
  margin: 0;
  padding: 0;
}

svg {
  max-width: 100%;
  height: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}
p {
  margin-bottom: 25px;
  line-height: 1.7;
}
.radius-50,
.ui.fluid.dropdown {
  border-radius: 50px;
}
.gray-background {
  background-color: var(--grey);
}
.primary-color,
.ctacomponent svg path {
  color: var(--primary);
  fill: var(--primary);
}
.custom-btn-hover {
  background-color: var(--primary);
}
.secondary-color {
  color: var(--secondary);
  fill: var(--secondary);
}

.secondary-color-bg {
  background-color: var(--secondary);
}
.title.text-white h4,
.outline-button .btn-8.bluebtn {
  color: var(--white);
}

.button-container-1.secondarybtn button {
  background-color: var(--primary);
}

.ctasection,
.outline-button .btn-8.bluebtn {
  background-color: var(--blue);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
}

main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 80px;
  width: 100%;
}
.title {
  margin-bottom: 50px;
  display: inline-block;
  width: 100%;
}
.title span {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: var(--letter3);
  color: hsl(0deg 0% 0% / 70%);
  padding-bottom: 10px;
  display: inline-block;
}

.title h4,
.title h4 span {
  color: var(--secondary);
  font-size: 36px;
  font-weight: 300;
  line-height: 42px;
  margin: auto;
  position: relative;
  width: 100%;
  letter-spacing: var(--letter1);
  text-transform: uppercase;
  display: grid;
}
.bannerh2::before {
  content: "";
  width: 125px;
  height: 5px;
  background: var(--primary);
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 0;
  transition: ease all 1s;
}
.p-100 {
  padding: 100px 0;
}

.letter1 {
  letter-spacing: var(--letter1);
}

.cardbox {
  width: 90%;
  margin-bottom: 10%;
  overflow: hidden;
}
.Services .iconcircle span svg,
.Services .iconcircle span,
.cardbox img,
.cardParent img,
.doctorsprofile img,
.iconcircle span svg {
  transition: ease all 0.5s !important;
}
.cardbox:hover img,
.cardParent:hover img,
.doctorslide:hover .doctorsprofile img,
.iconcircle:hover span svg {
  transform: scale(1.05);
}
.Services .iconcircle:hover span {
  background: var(--grey);
}

.Services .iconcircle:hover span svg path {
  fill: var(--primary);
}
.cardbox:before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--grey);
  position: absolute;
  right: 0;
  transition: ease all 0.5s;
  transition-delay: 0.5s;
}
.why_Patel .flex-center:nth-child(2) .cardbox:before {
  transition: ease all 0.6s;
  transition-delay: 0.6s;
}
.why_Patel .flex-center:nth-child(3) .cardbox:before {
  transition: ease all 0.7s;
  transition-delay: 0.7s;
}
.why_Patel .flex-center:nth-child(4) .cardbox:before {
  transition: ease all 0.8s;
  transition-delay: 0.8s;
}
.why_Patel .flex-center:nth-child(5) .cardbox:before {
  transition: ease all 0.9s;
  transition-delay: 0.9s;
}
.why_Patel .flex-center:nth-child(6) .cardbox:before {
  transition: ease all 1s;
  transition-delay: 1s;
}
.why_Patel .flex-center.aos-init.aos-animate .cardbox::before {
  width: 0;
}
.accreditation img {
  transition: ease all 0.5s;
  transition-delay: 0.75s;
  transform: scale(0, 1);
}
.accreditation .col-lg-4.col-sm-6.col-12.aos-init.aos-animate img {
  transform: scale(1, 1);
}
.gray_Background {
  background-color: var(--grey);
}

/* .bglayerimage {
  background-image: url("./images/bg-layer-banner.jpg");
} */

.radius-25 {
  border-radius: 25px;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}
.removeBand:before {
  display: none;
}
/* Headings */
.heading4 {
  font-size: 28px;
  line-height: 30px;
  font-weight: 300;
}

.heading2 {
  font-size: 50px;
  line-height: 56px;
  font-weight: 300;
}
.heading3 {
  font-size: 36px;
  line-height: 46px;
  font-weight: 300;
}
.heading5 {
  font-size: 22px;
  line-height: 28px;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-800 {
  font-weight: 800;
}
.fw-300 {
  font-weight: 300;
}
.fw-100 {
  font-weight: 100;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: var(--font);
  line-height: inherit;
  font-size: inherit;
  margin: calc(2rem - 0.14285714em) 0 1rem;
  font-weight: inherit;
  padding: 0;
}
/* Doctor Detail */
.doctorTitle:after {
  content: "";
  width: 100%;
  height: 1px;
  background: #b3b5b7;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.doctorTitle h4 {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin: 0;
  padding-right: 20px;
}
.doctorCont p {
  margin: 0 0 10px 0;
}
.container {
  max-width: 90%;
  width: 90%;
}
.web-container {
  width: 77%;
  margin: auto;
}
.number_Bg_Image:before,
.react-parallax::before {
  content: "";
  background: var(--black70);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.react-parallax::before {
  z-index: 1;
}
.technology .slick-list {
  width: 90%;
  margin: 0 auto;
}
.row-space-50 {
  row-gap: 50px;
}
.space-25 {
  row-gap: 25px;
}
.radius-0 {
  border-radius: 0;
}
a:hover {
  color: inherit;
}
.preloader {
  width: 100%;
  position: fixed;
  background: #fff;
  top: 16%;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
  height: 100vh;
}
.blue__circle_heading p,
.testimonialcont p {
  line-height: 1.5;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-100 {
  margin-bottom: 100px;
}
.pl-50 {
  padding-left: 50px;
}
.pl-100 {
  padding-left: 100px;
}
.cursor-pointer {
  cursor: pointer;
}
span.col-12.button-container-1.secondarybtn.primary-color.cursor-pointer.custom-btn-hover {
  color: var(--white);
}
.custom-btn-hover:hover {
  background-color: transparent;
}
span.col-12.button-container-1.secondarybtn.primary-color.cursor-pointer.custom-btn-hover:hover {
  color: var(--black70);
}
.fst-italic {
  font-family: "AvertaStd-RegularItalic";
}
.pageContent h3 {
  font-weight: bold;
  color: var(--black);
}
.pageContent p,
.pageContent ul li,
.pageContent ol li {
  margin-bottom: 5px;
}
.MuiMenu-list {
  height: 50vh;
}
/* Transplant Report */
.accordionTransplant .MuiPaper-rounded {
  margin-bottom: 10px;
  box-shadow: none;
}

.accordionTransplant .MuiPaper-rounded:before {
  content: "";
  background: transparent;
}
.accordionTransplant .MuiPaper-rounded .MuiButtonBase-root {
  padding: 0px 30px;
  background: var(--secondary);
  border-radius: 50px;
  color: var(--white);
  height: 80px;
}
.accordionTransplant .MuiPaper-rounded .MuiButtonBase-root span {
  font-family: var(--font);
}

.MuiAccordionSummary-content {
  margin: 0 !important;
}
[data-testid="ExpandMoreIcon"] {
  fill: var(--white) !important;
}
.MuiSvgIcon-root {
  width: 35px;
  height: 35px;
}
.downloadBox {
  background: #eaf2ff;
  padding: 25px;
  text-align: center;
  border: solid 5px var(--white);
  border-radius: 25px;
}
.transPlant .downloadBox:nth-child(even) {
  background: #fff5f5;
}
.downloadBox svg {
  width: 40px;
  height: 40px;
  margin: 15px 0 0 0;
  opacity: 0.2;
  transition: ease all 0.5s;
}
table {
  background-color: transparent;
}
.spanLeft span {
  float: left;
}
caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}
.text-lg-custom-start {
  text-align: left !important;
}
.desktop-show {
  display: block !important;
}
.mobile-show {
  display: none !important;
}
.erroMessage {
  color: rgb(211, 47, 47);
  font-family: var(--font);
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
@media (max-width: 1600px) {
  .web-container {
    width: 90%;
  }
  .heading3 {
    font-size: 28px;
    line-height: 36px;
  }
  .heading2 {
    font-size: 36px;
    line-height: 44px;
  }
  /* :root {
    --main-font-size:16px;
    --main-line-height:22px;
    --midFont: 13px;
    --letter2:1.5px
} */
  .heading4 {
    font-size: 24px;
    line-height: 30px;
  }
}
@media (max-width: 1440px) {
  main {
    gap: 50px;
  }
  .title h4,
  .title h4 span {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (max-width: 1380px) {
  .title span {
    font-size: 13px;
    line-height: 20px;
  }
  .position-relative.col-lg-11.col-12.m-auto.slider {
    width: 100%;
  }
}
@media (max-width: 1040px) {
  .About_Section p,
  .coreValues p {
    font-weight: 300;
    color: var(--black);
  }
  .blue__circle_heading p {
    color: var(--white);
  }
  .desktop-show {
    display: none !important;
  }
  .mobile-show {
    display: block !important;
  }
  .p-100 {
    padding: 50px 0;
  }
  .title h4,
  .title h4 span {
    font-size: 28px;
    line-height: 36px;
  }
  .title {
    margin-bottom: 25px;
  }
  .bglayerimage .row,
  .ctasection .col-12 .row {
    row-gap: 25px;
  }
  .appoinmentNumber {
    border: none !important;
    border-top: solid 1px #dee2e6 !important;
    margin-top: 50px;
  }
  .spanLeft span {
    float: none;
  }
  .text-lg-custom-start {
    text-align: center !important;
  }
  p {
    margin-bottom: 15px;
  }
  .bookAnAppointment .row.justify-content-between {
    flex-direction: column-reverse;
  }
}
@media (max-width: 680px) {
  .heading2 {
    font-size: 30px;
    line-height: 38px;
  }
  .heading3 {
    font-size: 26px;
    line-height: 34px;
  }
  .web-container {
    width: 100%;
    padding: 0;
  }
  .title span {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2px;
  }
  .title span {
    width: 100%;
    float: left;
  }
  .title h4 {
    padding: 0 20px;
    display: contents;
    width: 100%;
    float: left;
  }
}
@media (max-width: 420px) {
  .title h4,
  .title h4 span {
    font-size: 22px;
    line-height: 32px;
  }
}
@media (max-width: 390px) {
  .heading2 {
    font-size: 24px;
    line-height: 32px;
  }
  .heading3 {
    font-size: 21px;
    line-height: 28px;
  }
  :root {
    --main-font-size: 16px;
    --main-line-height: 22px;
  }
  .heading4 {
    font-size: 20px;
    line-height: 26px;
  }
}
@media (max-width: 350px) {
  .title h4,
  .title h4 span {
    padding: 0;
    font-size: 19px;
    line-height: 26px;
  }
}
