.equireheading {
  width: 100%;
  position: relative;
}
.equireheading article {
  font-weight: 600;
  text-align: center;
  position: relative;
  margin: 15px 0;
  padding: 5px 0;
}

.equireheading article:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #000;
  position: absolute;
  left: 50%;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.equireheading article:after {
  content: "";
  background: rgb(250 250 252);
  position: absolute;
  width: 80%;
  height: 100%;
  left: 10%;
}
.equireheading span {
  position: relative;
  z-index: 1;
  color: #000;
  padding: 0 10px;
  display: inline-block;
  background: rgb(250 250 252);
}
.inputmodified label {
  font-weight: normal;
  text-transform: capitalize;
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 26px;
  top: 3px;
  letter-spacing: 0.5px;
  left: 10px;
}
