:root {
  --font-family: "AvertaStd", sans-serif;
  --primary-color: #663487;
  --secondary-color: #257bbf;
  --white-color: #fff;
  --bg-color: #deebf4;
  --btn-color: #0599d2;
  --error: #d32f2f;
  --bg-error: rgb(211 47 47 / 5%);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "AvertaStd", sans-serif !important;
}

a,
.contact a,
.fheading article a,
.homecollection article a,
.textbtn a,
.slimenu ul li a,
.leftmenu .button a {
  color: inherit;
  text-decoration: none !important;
}

body {
  font-family: var(--font-family) !important;
  color: #000;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
}

* {
  outline: 0;
}

svg {
  width: 100%;
  height: auto;
}

.container {
  min-width: 90%;
  margin: auto;
}

.testimonialbox,
.web-container,
.quality {
  width: 85%;
  margin: auto;
}

.button {
  float: left;
  min-width: 140px;
  display: block;
  border: none;
  background: none;
  color: inherit;
  position: relative;
  z-index: 1;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  background-color: var(--btn-color);
  color: var(--white-color);
  overflow: hidden;
  transition: color 0.3s;
  text-align: center;
  line-height: 50px;
  border-radius: 50px;
  font-size: 14px;
  text-transform: uppercase;
}

.button--aylen.button--inverted {
  background: none;
  color: var(--white-color);
}

.button--aylen::before,
.button--aylen::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 100%;
  left: 0;
  z-index: -1;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}

.button--aylen::before {
  background: #7986cb;
}

.button--aylen::after,
.gradient {
  background: linear-gradient(80deg, #611ca2, #1198ca);
}

.button--aylen:hover {
  color: var(--white-color);
}

.button--aylen:hover::before,
.button--aylen:hover::after {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.button--aylen:hover::after {
  -webkit-transition-delay: 0.175s;
  transition-delay: 0.175s;
}

h1,
h2,
h3,
h4,
h5,
h6,
.slick-slider .slick-dots li,
.detailrow p,
.headersearchbox.enquireform .MuiInputBase-root {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.grey-background {
  background-color: rgb(245, 254, 255);
}

.homecollection span {
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

.homecollection article {
  font-size: 27px;
  color: #0599d2;
  font-weight: bold;
  line-height: 30px;
  padding: 0;
  margin: 0;
}

.menslide {
  position: fixed;
  background: #fffafa;
  width: 100%;
  left: 0;
  top: -100vh;
  line-height: 50px;
  transition: ease all 0.5s;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all linear 0.5s;
  z-index: 1;
}

.hemburgur span {
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 1px;
  background: rgb(0 0 0 / 50%);
  transition: 0.2s ease-in-out;
  float: left;
  position: absolute;
  border-radius: 100%;
  right: 0;
}

.togmenu li {
  padding: 8px 0;
}
.navbar {
  border: none;
  outline: none;
}

.hemburgur {
  width: 25px;
  height: 40px;
  aspect-ratio: 25 / 40;
  cursor: pointer;
  transform: rotate(0deg);
  position: relative;
  float: right;
  z-index: 2;
  right: 0;
  background: transparent;
  border: none;
  outline: 0;
  margin-left: 25px;
}

.hemburgur span:nth-child(1) {
  top: 0;
}

.hemburgur span:nth-child(2) {
  top: 14px;
}

.hemburgur span:nth-child(3) {
  top: 28px;
}

.header {
  padding: 15px 0;
}

.title,
.all-test .sliderbox {
  margin-bottom: 50px;
}

.hemburgur.active span:nth-child(1) {
  transform: rotate(45deg);
  top: 15px;
  left: 5px;
  width: 100%;
  height: 2px;
  border-radius: 0;
}

.hemburgur.active span:nth-child(2) {
  opacity: 0;
}

.hemburgur.active span:nth-child(3) {
  transform: rotate(-45deg);
  top: 15px;
  left: 5px;
  width: 100%;
  height: 2px;
  border-radius: 0;
}

.menslide.active {
  opacity: 1;
  visibility: visible;
  top: 0;
}

img {
  max-width: 100%;
}

.searchbutton {
  position: absolute;
  right: 0;
  background-color: var(--btn-color);
  top: 0;
  bottom: 0;
  width: 80px;
}

.searchbutton svg path,
.discountprice svg path {
  fill: var(--white-color) !important;
}

.discountprice svg path {
  stroke: var(--white-color) !important;
}

.searchbutton svg {
  width: 100%;
  font-size: 32px;
  font-weight: 100;
}

.enquireform .MuiInputBase-root,
.prescription-form .input-field label {
  border-radius: 35px;
  background-color: var(--white-color);
  text-align: left;
  overflow: hidden;
  margin-bottom: 20px;
}

.enquireform .MuiInputBase-root label {
  font-weight: normal;
  text-transform: capitalize;
  font-family: var(--font-family);
  letter-spacing: 0.5px;
  position: relative;
}
.prescription-form .input-field label {
  background-color: var(--white-color);
}
.MuiInputBase-root.formbtn {
  line-height: 56px;
  color: #607586;
  letter-spacing: 0;
  border: solid 1px #abb5bb;
  max-height: 56px;
}

.MuiInputBase-root.formbtn.download {
  text-indent: 8px;
}

main {
  gap: 75px;
}

section,
.slick-list,
.testimonialshape,
.slick-slider {
  width: 100%;
  float: left;
}

.title,
.testimonialbox,
.slimenu ul li {
  position: relative;
}

.title h1,
.title h2,
.title h3 {
  margin: 0;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-bottom: 20px;
}
.title h1 {
  text-transform: none;
}

.title h1:after,
.title h2:after,
.title h3:after {
  content: "";
  width: 50px;
  height: 5px;
  display: inline-block;
  background: var(--secondary-color);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.sliderbox {
  background: var(--white-color);
  border-radius: 25px;
  padding: 25px;
  text-align: center;
  gap: 15px;
  position: relative;
  transition: ease-in all 0.1s;
  margin: 20px auto;
  max-width: 350px;
  box-shadow: 4px 4px 20px rgb(217 229 236);
}

.sliderbox:hover {
  background: #f7fafd;
  transform: scale(1.01);
}

.packagename span,
.circleicons span {
  background-color: var(--bg-color);
  border-radius: 100%;
  margin: auto;
  width: 140px;
  height: 140px;
}
.packagename span {
  width: 110px;
  height: 110px;
}

.packagedetail ul {
  padding: 0;
  margin: 0;
  margin-top: 12px;
  gap: 15px;
}

.packagedetail ul li {
  position: relative;
  font-size: 15px;
}

.packagedetail ul li:before {
  content: "";
  width: 30px;
  height: 2px;
  background: #ff0000;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.packagedetail ul li:last-child:before {
  opacity: 0;
}

.actualprice {
  font-size: 28px;
  font-weight: bold;
  line-height: 36px;
  position: relative;
}

.actualprice svg,
.discountprice svg {
  width: 30px;
  height: 30px;
  position: relative;
  left: 5px;
}

.discountprice {
  font-weight: bold;
  border-radius: 25px;
  font-size: 28px;
  line-height: 50px;
  width: 150px;
  margin: 0px auto;
}

.packagename h3 {
  font-size: 18px;
  display: grid;
  line-height: 26px;
  margin-bottom: 20px;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.flex-center,
.discountprice,
.actualprice,
.packagename span,
.searchbutton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-center,
.packagedetail ul,
.sliderbox {
  display: grid;
  align-items: center;
  justify-content: center;
}

/* 
.actualprice:before {
  content: '';
  width: 70px;
  position: absolute;
  height: 2px;
  background: #ff0000;
  transform: rotate(45deg);
} */
.actualprice span {
  position: relative;
  width: fit-content;
}

.actualprice span:after {
  content: "";
  width: 100%;
  position: absolute;
  height: 2px;
  background: #ff0505;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.title h1 span,
.title h2 span,
.title h3 span {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 20px;
}

.title h1.text-white,
.title h2.text-white,
.title h3.text-white {
  -webkit-text-fill-color: var(--white-color);
  background-image: inherit;
}
.condition a {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  aspect-ratio: 280/160;
}

.condtionicon {
  width: 25%;
  height: auto;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  aspect-ratio: 1/1;
}

.conditionshape {
  /* height: 150px; */
  overflow: hidden;
  transition: ease 0.15s ease;
}

.conditionshape span {
  /* position: absolute; */
  /* width: 100%; */
  bottom: 0;
  height: 100%;
}

.conditionshape h3 {
  position: absolute;
  margin: 0;
  text-align: center;
  width: 100%;
  right: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  line-height: 26px;
  color: #000;
}

.condtionicon path {
  fill: #284980;
}

.condition:nth-child(1) .conditionshape path {
  fill: #5dd6b3;
}

.condition:nth-child(2) .conditionshape path {
  fill: #6295d7;
}

.condition:nth-child(3) .conditionshape path {
  fill: #fef7a6;
}

.condition:nth-child(4) .conditionshape path {
  fill: #e5e4f4;
}

.condition:nth-child(5) .conditionshape path {
  fill: #d98594;
}

.condition:nth-child(6) .conditionshape path {
  fill: #fcd29f;
}

.condition:nth-child(7) .conditionshape path {
  fill: #e0d3de;
}

.condition:nth-child(8) .conditionshape path {
  fill: #c7efcf;
}

.circleicons {
  margin: 15px 0;
}

.footerrow {
  justify-content: space-between;
  margin: 0 auto;
}

.footercolumn,
.fheading article,
.slimenu ul li {
  font-size: 14px;
  line-height: 26px;
}

.fheading article {
  font-weight: 600;
  letter-spacing: 1.5px;
}

.footergray {
  background-color: #d8dee8;
  padding: 50px 0;
}

.ftrbdr {
  border: solid 1px #d3d3d3 !important;
}

.footergray p {
  font-size: 13px;
  letter-spacing: 0.5px;
  color: #8f8f8f;
}

.bg-gradient-image {
  bottom: -50%;
  z-index: -1;
}

.bg-gradient-image.about_page {
  bottom: -470%;
  z-index: -1;
}

.package_card .packagename h3 {
  height: 55px;
  overflow-y: hidden;
}

.detailtitle {
  display: flex;
  align-items: center;
}

.package_card .packagedetail ul {
  height: auto;
}

.package_card .packagedetail {
  height: 130px;
  overflow: hidden;
  display: none;
}

.packagedetail {
  display: inline-block;
  width: 100%;
}

.textbtn {
  margin-top: 25px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.5px;
  transition: ease all 0.5s;
  position: relative;
  display: inline-block;
  padding-bottom: 5px;
}

.textbtn:hover {
  letter-spacing: 4px;
}

.textbtn:after {
  content: "";
  width: 0;
  position: absolute;
  height: 1px;
  bottom: 0;
  background: #646464;
  left: 0;
  transition: ease all 0.5s;
}

.textbtn:hover:after {
  width: 100%;
}

.packagename h5 {
  margin: 0;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #0f8cd2;
  padding: 10px 30px;
  border-radius: 25px;
  color: var(--white-color);
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
}

.condition {
  margin-bottom: 25px;
}

.organcolumn svg path,
.circleicons svg path {
  fill: var(--white-color);
}

.circleicons svg {
  width: 70px;
  height: auto;
  margin: 0 auto;
}

.testimonial {
  background-image: url("./images//testimonial-banner.webp");
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: right;
}

.testimonial:before {
  background: rgb(37 123 191 / 74%);
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.testimonialcont {
  position: absolute;
  width: 93%;
  left: 0;
  padding: 0 30px;
}

.testimonialbox p {
  text-align: center;
  color: rgb(0 0 0 / 60%);
  font-size: 17px;
  line-height: 22px;
}

.testimonialbox h4 {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  float: left;
  text-align: center;
}

.cta span svg {
  margin: 0px auto;
  transition: ease all 0.5s;
}

.call svg {
  width: 50px;
}

.whatsapp svg {
  width: 50px;
  height: 48px;
  margin: 0 auto;
}

.cta,
.testimonialcont {
  margin-top: 50px;
}

.hsection {
  top: 15%;
  left: -3%;
  opacity: 0.5;
}

.endminus {
  right: -3%;
}

.svgwidth {
  width: 150px;
  height: 150px;
  aspect-ratio: 1/1;
}

.svgwidthline {
  width: 80px;
  opacity: 0.5;
}

footer {
  margin-top: 75px;
}

.footergray p a {
  color: #8f8f8f;
  text-decoration: none;
}

.no-buttons .slick-prev,
.no-buttons .slick-next {
  display: none !important;
}

/* .slick-slider .slick-dots {
  bottom: 15px;
  left: 95%;
  width: 100%;
  transform: translate(-50%, -50%);
} */

.slick-slider .slick-dots li button:before {
  font-size: 10px;
  color: var(--secondary-color);
  opacity: 1;
}

.slick-slider .slick-dots li.slick-active button:before {
  color: var(--white-color);
}

.inputmodified label,
.font label {
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 20px;
  top: 3px;
  letter-spacing: 0.5px;
  left: 10px;
}

.my-cart sup {
  font-size: 12px;
  background: #000;
  width: 20px;
  height: 20px;
  display: inline-block;
  line-height: 20px;
  border-radius: 100%;
  left: 2px;
  overflow: hidden;
}

.arrows .slick-next,
.arrows .slick-prev {
  width: 80px;
  height: 80px;
  z-index: 1;
}

.arrows .slick-next:before {
  content: "";
  background-image: url("./images/svg/right.svg");
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-size: cover;
  right: 25%;
}

.arrows .slick-prev:before {
  content: "";
  background-image: url("./images/svg/left.svg");
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-size: cover;
  left: 25%;
}

.productslider .slick-list {
  width: 90%;
  margin: 0px 5%;
}

.slick-slider.minusbottom .slick-dots {
  bottom: -5%;
}

.slick-slider.navigationwhite .slick-dots {
  bottom: -10%;
}

.slick-slider .slick-dots li button:before {
  color: #000;
  transition: ease all 0.5s;
}

.slick-slider .slick-dots li.slick-active button:before {
  color: var(--secondary-color);
  font-size: 15px;
}

.seasonalpack .sliderbox {
  margin-top: 4vh;
  border: solid 1px var(--bg-color);
}

.slick-slider.navigationwhite .slick-dots li button:before {
  color: var(--white-color);
}

.hovershadow .sliderbox:hover {
  box-shadow: 4px 4px 20px rgb(21 42 119 / 24%);
}

.organcolumnrow {
  width: 96%;
  margin: 0 auto;
}

.title h1.text-white:after,
.title h2.text-white:after,
.title h3.text-white:after {
  background-color: var(--bg-color);
}

.top-20 {
  top: 20%;
}

.left-inherit {
  left: inherit;
}

.top-inherit {
  top: inherit;
}

.svgwidthline path {
  fill: #4462ae;
}

.p-80,
.testimonial {
  padding: 80px 0;
}

.leftmenu .button {
  min-width: 50px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}
.leftmenu .button svg {
  width: 20px;
  color: #000;
}

.__menus {
  margin-left: 4%;
}
svg.color-black path {
  fill: #607586;
}

.fixed {
  -webkit-animation: smoothScroll 1s forwards;
  animation: smoothScroll 1s forwards;
  left: 0;
  position: fixed;
  top: 0;
}

.header.fixed {
  box-shadow: 0 3px 3px rgb(0 0 0 / 12%);
  width: 100%;
  z-index: 3;
  background: var(--white-color);
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0px);
  }
}

.header.fixed .menslide.fixvisible {
  visibility: visible;
  opacity: 1;
}

.header .menslide.fixvisible {
  top: 100%;
  height: auto;
  z-index: 2;
  display: inline-block;
}

header {
  min-height: 100px;
  background-color: var(--white-color);
  margin-bottom: 20px;
}

.gradient-layer {
  width: 20px;
  position: absolute;
  background-color: var(--primary-color);
  right: 0;
  top: 5%;
  bottom: 5%;
}

.cta span:hover svg {
  transform: scale(1.15);
}

.slimenu ul li {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 8px;
  transition: ease all 0.5s;
}

.slimenu ul li span,
.MuiInputBase-root.formbtn span,
.leftmenu span span svg {
  width: 30px;
  margin-right: 10px;
}

.slimenu ul li span,
.leftmenu span span svg {
  width: 25px;
  display: inline-block;
}

.logo {
  width: 135%;
  transition: 0.3s ease all;
  z-index: 2;
  position: relative;
}

.fixed .logo {
  width: 115%;
}

.nah_logo svg {
  width: 75px;
  aspect-ratio: 1 / 1;
}
.nah_logo svg #nabh-conatoiner {
  margin: auto;
  align-items: 1/1;
}

.logo {
  transition: ease all 0.5s;
}

.minheight-120 {
  min-height: 120px;
}
.header_columns {
  flex-wrap: wrap;
  width: 40%;
  columns: 2;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 30px;
  z-index: 101;
}

.header_columns .footercolumn {
  width: 40%;
}

.organcolumn .textbtn {
  margin: 0;
  font-weight: normal;
}

.hsection circle,
.hsection path {
  fill: #c7c7c7;
}

.organcolumn .textbtn:after {
  background: #c8c5c5;
}

.headersearchbox {
  width: 42%;
  transition: ease all 0.5s;
}

.hidden_header {
  visibility: hidden;
  opacity: 0;
}

.headersearchbox .inputmodified {
  border-radius: 160px;
}

.headersearchbox .inputmodified .MuiInputBase-root {
  border-radius: 50px;
}

.fixed .headersearchbox {
  opacity: 1;
  visibility: visible;
}

.headersearchbox .inputmodified .MuiInputBase-root .searchbutton {
  background: transparent;
}

.headersearchbox .inputmodified .MuiInputBase-root .searchbutton svg path {
  fill: #909090 !important;
}

.slimenu ul li:before {
  content: "";
  background: #2598cf;
  position: absolute;
  width: 100%;
  height: 0;
  z-index: -1;
  transition: ease all 0.5s;
  top: 0;
}

.slimenu ul li:hover:before {
  height: 100%;
}

.slimenu ul li:hover {
  color: var(--white-color);
}

.slimenu ul li:hover span svg path {
  fill: var(--white-color) !important;
}

/* About Us  */
.colorhomecollection {
  background-color: var(--primary-color);
  padding: 40px;
  border-radius: 50px;
}

.colorhomecollection h2:before,
.colorhomecollection h2:after {
  opacity: 0;
}

.colorhomecollection h2 span {
  color: var(--white-color);
}

.colorhomecollection p {
  margin: 0;
  color: var(--white-color);
}

.chooseassure .col-lg-3:nth-child(1) .circleicons span {
  background: #5dd6b3;
}

.chooseassure .col-lg-3:nth-child(2) .circleicons span {
  background: #6295d7;
}

.chooseassure .col-lg-3:nth-child(3) .circleicons span {
  background: #fef7a6;
}

.chooseassure .col-lg-3:nth-child(4) .circleicons span {
  background: #d98594;
}

.chooseassure .col-lg-3:nth-child(5) .circleicons span {
  background: #fcd29f;
}

.chooseassure .col-lg-3:nth-child(6) .circleicons span {
  background: #c7efcf;
}

.our_diff_cnt .our_diff_item .circleicons svg path,
.chooseassure .chooseUsIcon:not(:first-child) .circleicons svg path {
  fill: #000;
}
.our_diff_item span:hover svg path {
  fill: #fff !important;
  color: #fff !important;
  transform: all ease 0.25s;
}

.quality li + li,
.dropdowntab ul li + li {
  margin: 10px 0;
}

.quality li {
  list-style: disc;
}

.organcolumnrow .sliderbox {
  box-shadow: none;
}

.highlights._points p {
  border-radius: 50px;
  border: solid 1px #d8dee7;
  padding: 10px 30px;
  transition: all ease 0.3s;
}

.detailrow {
  padding-bottom: 20px;
  border-bottom: solid 1px #ececec;
  margin-bottom: 20px;
}
.detailtitle {
  margin-bottom: auto;
}

.detailtitle p {
  padding: 0 25px;
}

.detailrow:last-child {
  border: none;
}

.accordion__item + .accordion__item,
.sitemapcol li + li,
.keyfeaturecont li {
  margin: 10px 0;
}

.accordion__button {
  background-color: var(--bg-color);
  color: #444;
  letter-spacing: 0.5px;
  cursor: pointer;
  padding: 18px 25px;
  width: 100%;
  text-align: left;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 35px;
  transition: ease all 0.5s;
  display: flex;
  justify-content: space-between;
}

.accordion__button span {
  padding-right: 25px;
}

.accordion__button:hover,
.faqcont [aria-expanded="true"] {
  background-color: #9cd6ff;
  transform: scale(1.01);
}

.accordion__panel ul li {
  list-style: disc;
  margin: 5px 0;
}

.detaildescrp .button.button--aylen svg {
  width: 30px;
}

.accordion__button:after {
  display: inline-block;
  content: "";
  position: relative;
  right: 0%;
  color: rgb(16 15 15 / 56%);
  content: "\002B";
  font-size: 24px;
  font-weight: 300;
  margin: 0;
}

.accordion__button[aria-expanded="true"]::after,
.accordion__button[aria-selected="true"]::after,
.categorymanager [aria-expanded="true"] .plusbutton:after {
  content: "\2212";
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.accordion__panel ul {
  margin: 10px 0;
  padding-left: 25px;
}

.chooseassure .circleicons span svg path {
  transition: ease all 0.25s;
}

.chooseassure .circleicons span:hover svg path {
  fill: var(--white-color) !important;
  stroke: var(--white-color);
  stroke-width: 0px;
}

.listdata {
  max-height: 320px;
  overflow: auto;
}

.dropdowntab {
  position: absolute;
  top: 100%;
  z-index: 2;
  background: #fff;
  box-shadow: 0px 0px 2px 2px rgb(214 214 214 / 29%);
}

.cart_lisiting_area::-webkit-scrollbar-track,
.revier_cnt::-webkit-scrollbar-track,
.listdata::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(71, 69, 69, 0.192);
  background-color: #f5f5f5;
}

.cart_lisiting_area::-webkit-scrollbar,
.revier_cnt::-webkit-scrollbar,
.listdata::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.cart_lisiting_area::-webkit-scrollbar-thumb,
.revier_cnt::-webkit-scrollbar-thumb,
.listdata::-webkit-scrollbar-thumb {
  background-color: #5a5252;
  border: 1px solid #555555;
}

.listdata ul {
  padding: 0 15px;
}

.listdata ul li {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #181818;
  border-bottom: solid 1px #f5f5f5;
  padding: 10px 0;
  margin: 0 !important;
}

.headersearchbox.enquireform .searchbutton {
  background: transparent;
}

.headersearchbox.enquireform .searchbutton svg path {
  fill: #e6e6e6 !important;
}

.headersearchbox.enquireform .dropdowntab {
  z-index: 3;
}

.headersearchbox.enquireform .MuiInputBase-root {
  background-color: var(--white-color);
}

.contentbox h2,
.contentbox h3 {
  border-left: 4px solid rgb(97 30 163);
  line-height: 1;
  margin: 20px 0 10px;
  padding-left: 10px;
}
.contentbox p,
.contentbox ul {
  padding-left: 10px;
  letter-spacing: 0.8px;
  font-weight: 400;
}
.contentbox ul {
  list-style: disc;
}
.contentbox ul li {
  padding-bottom: 5px;
  list-style: inside;
}
.contentbox ol li {
  list-style-type: decimal;
}

.contentbox {
  padding-bottom: 10px;
}
.privacy_pol_cnt a {
  font-weight: 600;
}

/* Upload from css */
.form-container {
  width: 100vw;
  height: 100vh;
  background-color: #19191a59;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.upload-files-container {
  background-color: #fbfbfb;
  width: 56%;
  padding: 40px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 10px 20px, rgba(0, 0, 0, 0.28) 0px 6px 9px;
  padding-bottom: 80px;
}

.drag-file-area {
  border: 2px solid #f1f1f1;
  border-radius: 40px;
  padding: 30px 50px;
  width: 50%;
  text-align: center;
  background: #f6f6f6;
  position: relative;
  padding-bottom: 100px;
  cursor: pointer;
}
.browse-files.alpha {
  position: relative;
  bottom: 25px;
  padding: 5px 20px;
  border: solid 1px #e1e1e1;
}
.dynamic-message {
  letter-spacing: 0.4px;
  display: inline-block;
  color: #bbb8b8;
}
.file-preview img.col-7 {
  max-height: 250px;
}
.upload-icon svg {
  fill: #bbb8b8;
}
.dynamic-message .success {
  margin: 5px 0;
  color: #20a757;
  background-color: #b7ebcc;
  display: inline-block;
  padding: 5px 18px;
}
.upload-icon svg {
  max-width: 50px;
}
.drag-file-area h3 {
  font-size: 22px;
  margin: 15px 0 0 0;
  letter-spacing: 0.5px;
}

.drag-file-area label {
  font-size: 17px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: end;
  justify-content: center;
  cursor: pointer;
}

.drag-file-area label .browse-files-text {
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
}

.browse-files span {
  position: relative;
  top: -25px;
}

.default-file-input {
  opacity: 0;
  display: none;
}
.prescription-form {
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.upload-prescription-form {
  width: 40%;
}

.cannot-upload-message {
  background-color: #ffc6c4;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 5px 10px 5px 30px;
  border-radius: 5px;
  color: #bb0000;
}

.cannot-upload-message svg {
  width: 25px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cannot-upload-message span,
.upload-button-icon {
  padding-right: 10px;
}

.cannot-upload-message span:last-child {
  padding-left: 20px;
  cursor: pointer;
}

.file-block {
  color: #f7fff7;
  background-color: rgb(73 156 205);
  transition: all 1s;
  width: 70%;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 15px;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
}

.file-info {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.file-icon {
  margin-right: 10px;
}

.file-name,
.file-size {
  padding: 0 3px;
}

.remove-file-icon {
  cursor: pointer;
}

.progress_bar {
  position: absolute;
  bottom: 0px;
  left: 2%;
  width: 0;
  height: 3px;
  border-radius: 25px;
  background-color: var(--btn-color);
  animation: progressBarAnimation 5s linear;
  /* Use an animation to change the width */
}

@keyframes progressBarAnimation {
  0% {
    width: 0;
  }

  100% {
    width: 96%;
  }
}

.check_icon svg {
  width: 30px;
}

.file-block svg {
  width: 20px;
}

.alpha span {
  display: contents !important;
}

._cross {
  position: absolute;
  content: "\292B";
  top: 15px;
  right: 15px;
  height: 25px;
  width: 25px;
  cursor: pointer;
}

/* cart css */
._cart {
  width: 24%;
  left: inherit !important;
  height: 100vh;
  right: -30% !important;
  font-family: inherit;
  box-shadow: 0 0 10px rgb(86 86 86 / 22%);
  transition: all 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}
.__cart_cnt {
  position: relative;
  right: 100%;
  transition: ease 1s all;
  animation-delay: 0.8s;
}

.__visible._cart {
  right: 0 !important;
  transition: 0.3s all cubic-bezier(0.42, 0, 0.58, 1);
  animation-delay: 0.8s;
}

._cart p {
  margin-bottom: 0;
}

.cart_product_img {
  height: 50px;
  width: 50px;
}

._cart_qnt {
  display: flex;
  flex-direction: row;
  align-items: center;
}

._cart_header {
  padding: 15px 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 2;
}

._cart_heading {
  margin: 0 auto;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}

._cart_test li::before {
  position: absolute;
  content: "";
  bottom: -15px;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: rgb(204 204 204 / 30%);
}

._cart_back_icon svg {
  font-size: 20px;
  height: 30px;
}

._cart_test_detail {
  align-items: center;
}

._cart_test_detail span {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.3;
  width: 90%;
}

.org_price p span {
  margin-left: 14px;
}

.rupee,
.org_price p span,
.dis_price {
  position: relative;
}

.rupee::before,
.org_price p span::before,
.dis_price p::before {
  position: absolute;
  content: "\20B9";
  top: auto;
  left: -15px;
  font-size: inherit;
  color: #353535;
}

.cart_itam_qnt {
  color: #525252;
  font-size: 14px;
  margin: 5px 0;
  letter-spacing: 1.3px;
}

.dis_price {
  font-size: 20px;
}

.sub_total {
  margin-top: 12px;
}

.cart_btn {
  display: flex;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  justify-content: flex-end;
}

._cart_list {
  margin: 20px 0;
  background: #f9f9f9;
  padding: 10px;
}

.bg_logo svg {
  height: 80px;
}

.bg_logo {
  position: absolute;
  opacity: 6%;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
}

.cart_itam_qnt {
  justify-content: center;
  align-items: center;
}

.empty_cart {
  margin-top: 12%;
}

.packagename_test {
  height: 55px;

  overflow: hidden;
}

.packagename_test h2,
.packagename_test h3 {
  font-weight: 600;
  font-size: 20px;
  text-wrap: balance;
  text-align: center;
  line-height: 30px;
  max-width: 95%;
  margin: 0 auto;
  letter-spacing: 0.3px;
}
._cart_cross {
  width: 23px;
  padding: 0;
}
.my-cart_cnt {
  font-style: normal;
  font-size: 12px;
  background-color: #ee3147;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  color: #fff;
  font-weight: 500;
}

.accordiancont ul li a,
.accordion__button a {
  color: #000;
  text-decoration: none;
}

.starrr {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.allservice-wrap a {
  /* color: #000; */
  text-transform: uppercase;
  font-size: 14px;
  border: 2px solid #e4e4e4;
  padding: 14px 28px;
  display: inline-block;
  transition: all 0.2s ease-in-out;
}

.allservice-wrap {
  text-align: center;
}

section.googlereview-sec {
  padding-top: 60px;
  border-top: 1px solid #e7e7e7;
  padding-bottom: 40px;
}

.googlereviewslider .revier_cnt {
  text-align: CENTER;
  font-size: 15px;
  line-height: 1.5;
  margin-top: 18px;
  text-wrap: balance;
  letter-spacing: 0.3px;
  height: 75%;
  overflow: auto;
  position: relative;
}
.googlereviewslider .revier_cnt::before {
  content: "";
  background-image: url("./images/testimonial-thumb.png");
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 60%;
  opacity: 6%;
  transform: translate(-50%, -50%);
  background-size: contain;
  background-repeat: no-repeat;
}
.googlereviewslider {
  max-width: 100%;
  width: 100%;
  margin: 15px auto 30px;
  color: #fff;
}
.google_testimonial {
  background: #fff;
  color: rgb(0 0 0 / 70%);
  padding: 40px 30px;
  margin: 10px;
  /* border-radius: 0 30%; */
  aspect-ratio: 1/1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex: 1 1;
  box-shadow: 2px 2px 12px rgba(44, 43, 43, 0.17);
}
.google_testimonial .revier_name {
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  position: relative;
  font-weight: 600;
}
.google_testimonial .revier_name:before {
  content: "";
  position: absolute;
  left: -60px;
  top: 50%;
  width: 50px;
  height: 1px;
  background: #949494;
}

.googlereviewslider .slick-prev:before,
.vedio_review .slick-prev:before {
  rotate: 180deg;
}

.vedio_review .slick-prev:before,
.vedio_review .slick-next:before,
.googlereviewslider .slick-prev:before,
.googlereviewslider .slick-next:before {
  display: block;
  content: "";
  background-image: url("./images/sharp\ arrow.webp");
  width: auto;
  height: 50px;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  filter: invert();
}
.vedio_review .slick-prev:before,
.vedio_review .slick-next:before {
  background-image: url("./images/svg/right_arrow.svg");
}
.googlereviewslider .slick-prev:before,
.googlereviewslider .slick-next:before {
  width: 75px;
  height: 75px;
}

.vedio_review .slick-prev {
  right: 8%;
  top: 110%;
  left: inherit;
  width: 100px;
  height: 100px;
}

.vedio_review .slick-next {
  left: inherit;
  width: 100px;
  height: 100px;
  right: 0.5%;
  top: 110%;
}

.googlereviewslider .slick-prev,
.googlereviewslider .slick-next {
  left: -70px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.googlereviewslider .slick-next {
  left: inherit;
  right: -30px;
}

.googlereviewslider .slick-dots {
  display: block !important;
}

.faqcont {
  margin: 0 auto;
}

.faqcont,
.faq .accordion__item + .accordion__item {
  margin: 15px auto;
}

.vedio_review .slick-prev:before,
.vedio_review .slick-next:before {
  filter: none;
}

.__trestmonail .button {
  color: #000;
  margin-top: 30px;
}

.__trestmonail .button:hover {
  color: #fff;
}

.search_loader {
  width: auto;
  height: auto;
  box-sizing: border-box;
  margin: 10% 0;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}

.search_loader .loader {
  width: 50px;
  height: 50px;
  margin: 0;
  background: transparent;
  border-top: 4px solid #7610c7;
  border-right: 4px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin ease infinite;
  animation: 1s spin linear infinite;
}

/* .search_loader:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background:#9c9b9b67;
} */
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.headersearchbox .header_search .form-control {
  width: 100%;
  height: 56px !important;
  font-size: 15px;
  background-color: #fff;
  border-radius: 35px !important;
  text-indent: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #000;
  margin-bottom: 0;
}

.headersearchbox .header_search .rbt-input-hint {
  background-color: #fff;
  font-size: 15px;
  text-indent: 15px;
  color: #000;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.enquireform .rbt-input-hint,
.enquireform .form-control {
  line-height: 56px;
  letter-spacing: 0.5px !important;
  color: #050505;
  border: solid 1px #abb5bb;
  max-height: 56px;
  border-radius: 35px;
  background-color: var(--white-color);
  text-align: left;
  overflow: hidden;
  margin-bottom: 15px;
  font-size: 16px;
  text-indent: 15px;
}

.form-control:focus {
  color: inherit;
  border-color: #000 !important;
  outline: 0;
  box-shadow: none !important;
}

.headersearchbox .form-control:focus {
  background-color: transparent !important;
}

.dropdown-menu.show {
  border-top: 0;
  border-radius: 0 0 35px 35px;
  z-index: 3;
}

.headersearchbox .dropdown-menu.show {
  z-index: 4;
}

.dropdown-item {
  padding: 15px 25px !important;
  color: #272727 !important;
}

.dropdown-item:hover,
.dropdown-item.active,
.dropdown-item:active {
  background-color: #d6e3f1 !important;
  color: #000000 !important;
}

.rbt-highlight-text {
  padding: 0 !important;
  color: inherit !important;
  background-color: transparent !important;
  font-weight: 600;
}

.rbt-aux {
  display: none;
}

.hsection {
  z-index: -1;
}

html::-webkit-scrollbar,
.packagedetail ul::-webkit-scrollbar,
.packagedetail::-webkit-scrollbar,
._acc_cnt ul li::-webkit-scrollbar,
#searchTypeahead::-webkit-scrollbar {
  width: 7px;
  background-color: #ebeaea;
  opacity: 0.8;
}

.packagedetail ul::-webkit-scrollbar,
.packagedetail::-webkit-scrollbar {
  width: 4px;
}

/* Handle */
html::-webkit-scrollbar-thumb,
.packagedetail ul::-webkit-scrollbar-thumb,
.packagedetail::-webkit-scrollbar-thumb,
._acc_cnt ul li::-webkit-scrollbar-thumb,
#searchTypeahead::-webkit-scrollbar-thumb {
  background: #acaaaa7e;
  border-radius: 1px;
  height: 30px;
}

html::-webkit-scrollbar-thumb {
  background-color: #969696;
}

/* Handle on hover */
html::-webkit-scrollbar-thumb:hover,
.packagedetail ul::-webkit-scrollbar-thumb:hover,
.packagedetail::-webkit-scrollbar-thumb:hover,
._acc_cnt ul li::-webkit-scrollbar-thumb:hover,
#searchTypeahead::-webkit-scrollbar-thumb:hover {
  background: #7e7d7d;
}

.button {
  user-select: none;
  cursor: pointer;
}

.package_card a,
.test_card a {
  color: inherit !important;
}

.slide-in {
  transform: translateX(0);
  /* Start at original position */
  transition: transform 1.3s ease-in-out;
  /* Add a smooth transition */
}

.slide-out {
  transform: translateX(100%);
  /* Move to the right (or adjust as needed) */
  transition: transform 3s ease-in-out;
  /* Add a smooth transition */
}

._icon {
  color: rgba(255, 255, 255, 0.95);
  right: 0;
  position: absolute;
  background-color: var(--btn-color);
  bottom: 0;
  width: 70px;
  top: 0;
  height: 100%;
  border-radius: 0 35px 35px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.h_form ._icon {
  height: 56px;
}

._icon svg {
  width: 100%;
  font-size: 32px;
  font-weight: 100;
}

.vedio_cont {
  width: 100%;
  padding: 0 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: relative;
}

.vedio_cont img {
  width: 100%;
}

.vedio_cont p {
  float: left;
  text-align: start;
  margin-right: auto;
}

.vedio_thumbnail {
  position: relative;
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #f5f5f5;
}

.vedio_thumbnail::before {
  content: "";
  background-color: #0000003f;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.vedio_cont i {
  display: none;
}

.vedio_cont:hover i {
  font-family: "Material Icons";
  font-size: 40px;
  color: hsl(0deg 0% 0% / 31%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

._acc_btn button {
  all: unset;
  min-width: 120px;
  text-transform: uppercase;
  letter-spacing: 1.8px;
}

._acc_btn {
  margin-bottom: 40px;
}

._acc_btn .underline {
  position: absolute;
  bottom: 0;
  background-color: #0599d2;
}

._acc_header h5 {
  margin-bottom: 10px;
}

._acc_header {
  gap: 60px;
}

.footer_tabs ul {
  justify-content: flex-start;
  flex-direction: row;
}

.footer_tabs ul li {
  margin-bottom: 5px;
  position: relative;
  max-height: 500px;
  overflow-y: scroll;
}

.footer_tabs ul li a:hover {
  font-weight: 600;
}

.footer_tabs ul li a {
  color: #000;
  position: relative;
  padding: 0 8px;
  transition: all 0.2s ease;
  font-size: 13px;
  border-right: 1px solid rgb(102 98 98 / 23%);
  text-transform: lowercase;
}

.footer_tabs ul li a:first-child {
  padding-left: 0;
}
.footer_tabs {
  opacity: 0;
  transition: opacity 0.15s ease; /* Add a smooth opacity transition */
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

/* Add keyframes for a smoother fade-in effect */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Add keyframes for a smoother fade-out effect */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 0.15s ease;
}

.fade-out {
  animation: fadeOut 0.15s ease;
}
.search_loader.vedio_revieew_loader {
  position: absolute;
  inset: 0;
  display: none;
}

.search_loader.vedio_revieew_loader.open {
  display: flex;
}

.iframe_scn {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

.footer_tabs ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.bread_crums .MuiBreadcrumbs-li {
  cursor: pointer;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 500;
}

.bread_crums .MuiBreadcrumbs-li a {
  color: inherit;
  font-weight: 600;
  letter-spacing: 0.5px;
}

li.MuiBreadcrumbs-li svg {
  width: 13px;
  margin-right: 2px;
  margin-bottom: 2px;
}

.MuiBreadcrumbs-separator.css-1wuw8dw-MuiBreadcrumbs-separator {
  margin-inline: 4px;
}

.bread_crums span.MuiChip-label {
  letter-spacing: 1px;
  margin: 2px 5px;
  text-transform: capitalize;
}
.css-1c35hjw-MuiPaper-root-MuiAccordion-root::before {
  background-color: transparent !important;
}

.css-cpgvjg-MuiSnackbar-root {
  top: 10vh !important;
  right: 24px !important;
  left: auto;
  bottom: auto;
  box-shadow: 8px 14px 17px -13px rgb(1 1 1 / 25%);
}

li.__tootip {
  display: inline-block;
  position: relative;
}

li.__tootip svg {
  width: 15px;
}
.tooltip_title_,
article .tooltip_title_ {
  text-align: left !important ;
  font-size: 15px !important;
  color: #000 !important;
  line-height: 1.7 !important;
  font-weight: 600 !important;
  margin-bottom: 12px !important;
  font-weight: 600;
}

p.tooltip_data_ {
  font-size: 13px !important;
  line-height: 1.4 !important;
  letter-spacing: 0.5px !important;
  text-align: left !important;
  text-wrap: wrap !important;
  text-transform: lowercase;
  margin: 0 auto !important;
}

.packagedetail._test ul {
  display: flex;
  flex-direction: row;
}

.packagedetail._test ul li {
  width: 45%;
  text-wrap: balance;
  font-size: 13px;
  line-height: 1.4;
}

.packagedetail._test ul li::before {
  content: none;
}

.packagedetail._test ul li svg {
  opacity: 0.5;
  font-size: 9px;
}

.detaildescrp .accordion__button:after {
  content: none;
}

.package_card .packageprice {
  height: 175px;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.home_banner .banner_slider {
  position: relative;
  display: flex;
}

/* .__Banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
} */
.h_form {
  width: 93%;
}
.home_banner img {
  width: 100%;
}

.banner_slide {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  z-index: 5;
  position: relative;
}
.home_banner img {
  width: 100%;
  height: auto;
}
.banner_slide a {
  width: 100%;
}

button:disabled {
  opacity: 0.8;
}

.slimenu ul li.cart_in_slimenu,
.cart_in_slimenu {
  display: none;
}

.leftmenu {
  display: flex;
}

.abt_img svg {
  width: 100%;
}

.our-ethos-content p {
  text-wrap: balance;
  letter-spacing: 1.3px;
}

.viewport {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
}

.scroll-container {
  position: absolute;
  overflow: hidden;
  z-index: 10;
  display: flex;
  justify-content: center;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.empty-cart-animation {
  position: relative;
  width: 200px;
  aspect-ratio: 1;
  box-shadow: 5px 7px 16px 3px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  overflow: hidden;
  background-color: #deebf4;
  margin-block: 25px 72px;
}

@media (min-width: 900px) {
  .empty-cart-animation {
    margin-block: 25% 72px;
  }
}

.empty-cart-animation .empty-cart-mask {
  position: absolute;
  top: 0;
  left: 0;
}

.empty-cart-animation .empty-cart {
  position: absolute;
  top: 50%;
  left: 50%;
  rotate: 10deg;
  translate: 200% -50%;
  animation: empty-cart-wheelie 6s infinite;
}

.empty-cart-animation .empty-cart:after {
  content: "";
  position: absolute;
  height: 0;
  box-shadow: -2px 0 14px 4px #353535;
  width: 46px;
  top: 100%;
  left: 55%;
  translate: -50% -50%;
}

@keyframes empty-cart-wheelie {
  25% {
    translate: -40% -50%;
    rotate: 0deg;
  }

  40% {
    translate: -40% -50%;
    rotate: 0deg;
  }

  65% {
    translate: -40% -50%;
    rotate: 15deg;
  }

  to {
    translate: -300% -50%;
    rotate: 15deg;
  }
}

._cart_back_icon {
  all: unset;
}

.add_item span {
  text-transform: capitalize;
  font-size: 23px;
  font-weight: 500;
}

/* 
.headersearchbox .header_search .isInput .form-control {
  border-radius: 35px 35px 0 0 !important;
  border-bottom: none;
  border-color: #c4c4c4 !important;
} */

/* .headersearchbox .header_search .rbt-input-main.form-control.rbt-input[data-has-input="true"] { */
/* .headersearchbox .header_search .rbt-input-main:not(:placeholder-shown), */
/* .headersearchbox .header_search .rbt-input-main:focus { */

.headersearchbox .header_search .rbt-input-main:focus:not(:placeholder-shown),
.rbt-input-main:focus:not(:placeholder-shown) {
  border-radius: 35px 35px 0 0 !important;
  border-bottom: none;
  border-color: #c4c4c4 !important;
}

.h_form .isInput .dropdown-menu.show {
  background-color: #deebf4 !important;
}

.h_form .isInputIcon._icon {
  background-color: transparent !important;
}

.h_form .isInputIcon._icon svg {
  color: #5f5f5f;
}

.headersearchbox .header_search ._icon {
  background-color: transparent !important;
}

.headersearchbox .header_search ._icon svg {
  color: #b5b5b5;
}

.scroll-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  will-change: transform;
}

.bread_crums {
  margin: 15px 0 30px;
}

.fmenu ul li,
.fmenu ul li a {
  color: #505050 !important;
  font-size: 12px;
  width: 100%;
  position: relative;
}

.chooseassure .circleicons:first-child svg path.s4 {
  fill: none !important;
  stroke: #ffffff;
  stroke-miterlimit: 10;
  stroke-width: 0.5;
}

.chooseassure .circleicons:first-child svg path.s6 {
  fill: none !important;
  stroke: #0000ff !important;
  stroke-miterlimit: 10;
  stroke-width: 1;
}

.chooseUsIcon:first-child .circleicons svg path.s5 {
  fill: #4672b7 !important;
}

.chooseUsIcon:first-child .circleicons svg path.s7 {
  fill: #ffffff !important;
}

.chooseUsIcon:first-child .circleicons svg path.s2 {
  fill: #a0ebfb !important;
}

.chooseUsIcon:first-child .circleicons svg path.s1 {
  fill: #ef0e5b !important;
}

.chooseUsIcon:first-child .circleicons svg path.s0 {
  fill: #fefefe !important;
}

.chooseUsIcon:first-child .circleicons svg path.s3 {
  fill: #13befc !important;
}

.chooseUsIcon:first-child .circleicons svg {
  width: 80% !important;
}

.stepConnectorLine {
  position: absolute;
  height: 2px;
  background-color: #007bff;
  /* Adjust the color as needed */
  transition: width 0.3s ease;
  bottom: 12px;
  /* Adjust the bottom position based on your layout */
}

.check_out {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.check_out_cnt {
  margin-top: 50px;
}

.edit_cart svg,
.formpatient svg {
  width: 18px;
}

.edit_cart {
  width: max-content !important;
}

.formpatient {
  width: 90%;
  margin: auto;
}

.formpatient .css-1ex1afd-MuiTableCell-root {
  background: #fdfdfd !important;
  font-family: inherit;
  font-size: 15px;
  padding: 23px 15px;
  letter-spacing: 1px;
}

.formpatient .css-1ygcj2i-MuiTableCell-root {
  padding: 23px 15px;
  font-family: inherit;
  background: #f8fbff !important;
  font-size: 16px;
}

.ml-auto {
  margin-left: auto !important;
}

.edit_cart {
  background: transparent;
  border: 1.5px solid var(--btn-color);
  color: var(--btn-color);
  padding: 0 20px !important;
  transition: ease 0.3s all;
  letter-spacing: 0.5px;
}

.edit_cart:hover {
  background-color: var(--btn-color);
  color: #fff;
}

/* gender radio button css */

.radio-buttons {
  width: 100%;
  margin: 0 auto;
  text-align: left;
  margin-top: 30px;
}

.custom-radio input {
  display: none;
}

.radio-btn {
  margin: 10px;
  width: 100px;
  height: 110px;
  border: 3px solid transparent;
  display: inline-block;
  border-radius: 10px;
  position: relative;
  text-align: center;
  box-shadow: 0 0 20px #c3c3c367;
  cursor: pointer;
}

.radio-btn > i {
  color: #ffffff;
  background-color: #5a98f5;
  font-size: 3px;
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%) scale(2);
  border-radius: 50%;
  padding: 3px;
  transition: 0.5s;
  pointer-events: none;
  opacity: 0;
  width: 21px;
  height: 21px;
  display: flex;
}

.radio-btn .hobbies-icon {
  width: 60px;
  height: 50px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.radio-btn .hobbies-icon img {
  display: block;
  width: auto;
  /* margin-bottom: 20px; */
  height: auto;
}

.radio-btn .hobbies-icon i {
  color: #c4d6f7;
  line-height: 80px;
  font-size: 60px;
}

.radio-btn .hobbies-icon h3 {
  color: #555;
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 5px;
}

/* Common style for checked state */
.custom-radio input:checked + .radio-btn {
  border: 2px solid #5a98f5;
  background: #eaf1fe59;
}

.custom-radio input:checked + .radio-btn > i {
  opacity: 1;
  transform: translateX(-50%) scale(0.9);
}

/* isHom collection */
.plans {
  margin-top: 15px;
}

.plans .plan input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.plans .plan {
  cursor: pointer;
  width: 48.5%;
}

.plans .plan .plan-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #e1e2e7;
  border-radius: 10px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
}

.plans .plan .plan-content img {
  margin-right: 30px;
  height: 72px;
}

.plans .plan .plan-details span {
  margin-bottom: 10px;
  display: block;
  font-size: 20px;
  line-height: 24px;
  color: #252f42;
  /* margin-bottom: 30px;
  padding-bottom: 10px; */
}

.container .title {
  font-size: 20px;
  font-weight: 500;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  color: #252f42;
  margin-bottom: 20px;
}

.plans .plan .plan-details p {
  color: #646a79;
  font-size: 14px;
  line-height: 18px;
}

.plans .plan .plan-content:hover {
  -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
  box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.plans .plan input[type="radio"]:checked + .plan-content:after {
  content: "";
  position: absolute;
  height: 15px;
  width: 15px;
  background: #216fe0;
  right: 20px;
  top: 20px;
  border-radius: 100%;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px #0066ff;
  box-shadow: 0px 0px 0px 2px #0066ff;
}

.plans .plan input[type="radio"]:checked + .plan-content {
  border: 2px solid #216ee0;
  background: #eaf1fe;
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}

/* .our_diff_cnt .our_diff_item svg path {
  color: #000;
  fill: #000;
} */

/* .our_diff_cnt .our_diff_item:hover svg path {
  color: #fff;
  transition: all 0.5 ease;
  fill: #fff;
} */

.our_diff_cnt .our_diff_item:nth-child(1) .circleicons span {
  background: #7986cb;
}

.our_diff_cnt .our_diff_item:nth-child(2) .circleicons span {
  background: #fef7a6;
}

.our_diff_cnt .our_diff_item:nth-child(3) .circleicons span {
  background: #d98594;
}

.plans_selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home_collection_data {
  background: #fbfbfb;
  border-radius: 15px;
  padding: 40px 50px;
}

.home_collection_data svg {
  width: 30px;
}

.hcd_radio_button {
  width: 105px;
  height: 115px;
  color: #3c3c3c;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0 0 20px #d3d3d367;
  margin: 1px;
  position: relative;
  border-radius: 10px;
  transition: opacity 0.25s ease-in-out, background-color 0.25s ease-in-out,
    color 0.25s ease-in-out;
}

.hcd_radio_button.checked {
  border: 2px solid #5a98f5;
  background-color: rgb(234 241 254);
  box-shadow: 0 0 20px #eaeff9eb;
}

.day-picker .slot-option {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
}

.hcd_date_seection {
  margin-left: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 30px;
}

.slot-option span {
  letter-spacing: 1px;
}

.hcd_radio_button_checked {
  position: absolute;
  top: 8px;
  right: 8px;
}

.hcd_radio_button_checked {
  height: 12px;
  width: 12px;
  background: #216fe0;
  right: 10px;
  top: 10px;
  border-radius: 100%;
  border: 2px solid #fff;
  box-shadow: 0px 0px 0px 2px #0066ff;
}

.hcd_date_seection .custom-radio svg {
  width: 15px;
}

.hcd_select_date .radio-buttons {
  margin: 0;
  width: auto;
}

.isHomeCollection.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before,
.isHomeCollection .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  content: none !important;
  opacity: 0;
  height: 0;
}

.isHomeCollection .css-15v22id-MuiAccordionDetails-root {
  padding: 0 !important;
}

.cookies_consent {
  line-height: 1.4;
  letter-spacing: 1px;
  right: 30px;
  bottom: -100%;
  background-color: #fdfdfd;
  border-radius: 5px;
  padding: 25px 30px;
  font-family: var(--font-family) !important;
  box-shadow: -2px 0px 15px rgba(112, 112, 112, 0.2);
  z-index: 15;
  gap: 10px;
  flex-direction: column;
  font-size: 15px;
  width: 430px;
  transition: 1s all cubic-bezier(0.42, 0, 0.58, 1);
}

.cookies_consent.IsCookies {
  bottom: 30px;
  transition: 0.6s all cubic-bezier(0.42, 0, 0.58, 1);
  animation-delay: 0.8s;
}

.cookies_consent .textbtn {
  padding: 0;
  margin: 0;
}

.cookies_consent svg {
  width: 25px;
  margin-right: 5px;
}

.cookies_consent_backdrop .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: transparent;
}

.textbtn.decline {
  opacity: 70% !important;
}

.prescription-form .input-field {
  flex-direction: column;
  gap: 25px;
}
.prescription-form button {
  width: 100%;
  margin-top: 35px;
}
.prescrition_form {
  border: 2px dashed #dde9f3;
  border-radius: 35px;
  padding: 40px 20px;
}

.stepper_cnt .css-1hv8oq8-MuiStepLabel-label {
  font-family: var(--font-family) !important;
  letter-spacing: 1px;
}
.enquireform .form-control,
.enquireform .rbt-input-hint,
.css-p0rm37,
.css-1bp1ao6,
label.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-mvoatl-MuiPaper-root-MuiAlert-root,
.header_search input,
.css-1x51dt5-MuiInputBase-input-MuiInput-input,
.css-wb57ya-MuiFormControl-root-MuiTextField-root,
.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root,
.css-l4u8b9-MuiInputBase-root-MuiInput-root,
.enquireform .MuiInputBase-root label,
.css-xsqe0f-MuiButtonBase-root-MuiButton-root,
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root,
.enquireform .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root,
.enquireform .css-wb57ya-MuiFormControl-root-MuiTextField-root,
.address .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root,
.address .css-v4u5dn-MuiInputBase-root-MuiInput-root,
.checkout_input .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root,
.checkout_input .css-v4u5dn-MuiInputBase-root-MuiInput-root,
.address label,
.checkout_input label,
.checkout_input div,
.checkout_input div,
.address input,
.checkout_input input {
  font-family: "AvertaStd", "sans-serif" !important;
  letter-spacing: 0.5px !important;
}

.css-p0rm37 {
  text-indent: 10px;
  font-size: 14px;
  line-height: 26px;
}

.css-mvoatl-MuiPaper-root-MuiAlert-root {
  letter-spacing: 0.5px;
}

label.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  text-indent: 10px;
  font-size: 14px !important;
  letter-spacing: 0.5px !important;
}

.form-control input:-webkit-autofill,
.form-control input:-webkit-autofill:hover,
.form-control input:-webkit-autofill:focus,
.form-control input:-webkit-autofill:active {
  background-color: transparent !important;
}
.css-4ff9q7.Mui-completed,
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #888bf1 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  color: #c6d0ff !important;
}
.css-10psl7k,
.MuiStepIcon-text.css-117w1su-MuiStepIcon-text {
  color: #fff !important;
  font-weight: 600;
  padding: 2px;
  font-family: var(--font-family);
}
.css-4ff9q7 {
  color: #c6d0ff !important;
}
.css-4ff9q7,
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  height: 28px;
  width: 28px;
}

.input_error {
  border: 1.5px solid var(--error) !important;
  background: var(--bg-error) !important;
}
.abt_banner {
  margin: 15px 0 10px;
}
.abt_banner img {
  width: 100%;
  height: 25vw;
  border-radius: 50px;
}
.__about__ .bread_crums {
  margin: 0;
}

.ethos_cnt {
  margin: 0 auto;
  gap: 20px;
}

.ethos_img img {
  width: 100%;
  height: auto;
}

.our-ethos {
  justify-content: space-evenly;
  margin-bottom: 10%;
}

.our-ethos-item {
  align-items: flex-start;
}

.our-ethos-title {
  font-size: 36px;
  font-weight: 100;
  letter-spacing: 4.4px;
  color: #4e4e4e;
  font-family: var(--font-family);
}
.whatsapp,
.call,
.fmenu ul li a {
  cursor: pointer;
}

/* HTML: <div class="loader"></div> */
._loader {
  width: 50px;
  height: 50px;
  padding: 3.2px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #2576b0;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.No_Data svg {
  width: 40%;
}

.disabled,
button:disabled {
  cursor: not-allowed !important;
  filter: grayscale(0.8);
}

.unsetAll {
  all: unset;
}

.search_icon {
  width: 24px;
}

.main_loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  inset: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
}

.main_loader_sec {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  background-color: #575aa8;
  flex: 1;
}

.main_loader_svg {
  height: 115px;
  margin: auto;
}

.main_loader_svg path {
  fill: #575aa8;
}

.main_loader_svg {
  opacity: 1;
  transform: opacity 0.3s;
}

/* not found css */

div#notfound {
  align-items: center;
  display: flex;
  float: left;
  justify-content: center;
  margin: 200px 0;
  width: 100%;
}

.notfound {
  text-align: center;
  width: 50%;
}

.notfound-404 h1 {
  font-weight: 600;
  font-size: 40px;
  letter-spacing: 2px;
  line-height: 2;
}

/* .notfound h1,
.notfound h2 {
  margin: 0;
} */

div#notfound a.active {
  color: #575aa8;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
}

.sucess_msg svg {
  height: 520px;
}

/* HTML: <div class="loader"></div> */
.loadersdfdgd {
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l4 1s infinite steps(10);
}

@keyframes l4 {
  to {
    transform: rotate(1turn);
  }
}

.no_data-1 {
  opacity: 0;
  animation: dotAnimation 1s infinite alternate;
}

.no_data-1._wifi {
  animation-direction: reverse;
  animation: dotAnimationReverse 1s cubic-bezier(0.29, 0.1, 0.34, -0.9) infinite
    alternate;
}

.not_found_text {
  font-weight: 700;
}

@keyframes dotAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes dotAnimationReverse {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.detaildescrp {
  padding-right: 30px !important;
}
._flex_center_col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.packageprice {
  margin: 12px 0;
  gap: 12px;
}
.detaildescrp .actualprice svg {
  left: 0;
}
.sucess_msg text {
  font-size: 85px;
  font-weight: 600;
}
.pt-80 {
  padding-top: 80px;
}

.app-check input {
  display: none;
}

.app-check {
  display: flex;
  transition: all 0.15s ease-out 0s;
  gap: 10px;
  max-width: 100%;
  flex-wrap: wrap;
}
.app-label {
  position: relative;
  line-height: 35px;
  font-size: 15px;
  font-weight: 600;
  color: #353434;
  border: 1.5px solid #353434;
  border-radius: 40px;
  padding: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-assure-text {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 16px;
  line-height: 1.6;
  letter-spacing: 0.6px;
  text-wrap: balance;
}

.app-check input:checked + .app-label {
  color: #5a98f5;
  border: 1.5px solid #5a98f5;
  background: #eaefff;
}
.app-border {
  transition: opacity 0.3s ease; /* Add a transition for a smooth effect */
}
i.my-cart_cnt.mycart_qty_high {
  width: 25px;
  height: 25px;
  transition: all 0.3s ease;
}
table {
  margin: 0;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: rgb(249 253 255);
  border-bottom: 1px solid #c9e1f5;
}
thead {
  background: rgb(213, 233, 253);
}
table th,
table td {
  padding: 20px 12px;
  text-align: center;
}

table th {
  padding: 22px 20px;
}
table th {
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: rgb(180 210 255 / 94%);
}
.effectheading.newheading {
  font-size: 57px;
  text-align: center;
  color: #cccaca;
  background: none !important;
}
.menustn {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0;
}
.noData {
  max-width: 50% !important;
  width: 600px !important;
  margin: 0 auto !important;
  margin-top: 8vh !important;
}
.our-founder-title {
  width: 35%;
}
.our-founder-title h2 {
  font-size: 72px;
  line-height: 60px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1;
  color: #1f2323;
}
.our-founder-content {
  width: 65%;
}
.founder-card {
  background-color: #fff;
  border: 1px #f6f6f6 solid;
  width: 385px;
  margin: 15px;
  padding: 30px;
  height: 780px;
  transition: scale ease 0.4s;
  cursor: pointer;
  box-shadow: -1px 2px 9px rgba(0, 0, 0, 0.1);
}

.founder-image {
  width: 240px;
  height: 240px;
  background: #f3f3f3;
  background-position: 50% 50%;
  margin: 0 auto 0 0;
  border-radius: 50% !important;
  border: 1px solid #e5e5e5;
}
.founder-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.founder-social-icon {
  display: none;
  margin: 0 7px;
}
.founder-social-icon svg {
  width: 23px;
  fill: #898989;
}
.founder-card-body {
  flex-direction: column;
}
.founder-details {
  min-height: 195px;
}
.footercolumn .fheading article {
  font-size: 16px;
  text-wrap: nowrap;
  margin-bottom: 15px;
}
.faqcont h3 {
  all: unset;
  font-size: 18px;
}
.detailtitle h2 {
  font-size: 17px;
  padding: 0 25px;
  margin: 0;
}
.circleicons h3 {
  all: unset;
  text-wrap: balance;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
.privacy_pol_cnt h3,
.contentbox h2,
.contentbox h3 {
  font-size: 22px;
}
._acc_header article {
  font-weight: 500;
  padding-bottom: 5px;
}

.faqcont .accordion__button h3 {
  all: unset;
}
.app-time p {
  font-size: 20px;
  font-weight: 500;
  flex-basis: 100%;
  color: #252f42;
  margin: 10px 0;
}

.detaildescrp {
  max-width: 100%;
}

.skeleton {
  display: flex;
  flex-direction: column;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
}

.line {
  height: 15px; /* Adjust the height of each line */
  margin-bottom: 10px; /* Adjust the spacing between lines */
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
._loader_cnt {
  height: 70dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slot-time-not {
  font-size: 15px !important;
  background-color: #efefef;
  padding: 5px 10px;
  margin: 10px 0 10px 25px !important;
  font-weight: 600;
}
.slot-time-not a {
  color: #000;
}
.error_message {
  color: var(--error);
  background-color: var(--bg-error);
  padding: 5px 18px;
  font-weight: 600;
}

.success_data {
  width: 60%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
}
.sucess_msg {
  height: 50vh;
}
.sucess_msg svg {
  height: 520px;
  position: absolute;
  top: 0;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}
.popular_packaes_title {
  font-size: 19px;
  text-transform: uppercase;
}
.popular_packages_cnt ul {
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;
  row-gap: 10px;
  justify-content: space-between;
}
.popular_packages_cnt li {
  width: 23%;
  padding-left: 5px;
  border-left: solid 1px #b9b9b9;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.popular_packages_cnt li a {
  color: #494a4b;
  font-size: 13px;
  letter-spacing: 0.5px;
}
.popular_packages_cnt {
  margin-top: 22px;
}
.popular_packages.bt {
  border-top: 1px solid #a9a8a89e;
}
.footergray._chnage_color {
  background-color: rgb(205 215 227);
}
.footer_bottom {
  flex-direction: row-reverse;
  display: flex;
  justify-content: space-between;
  border-bottom: 1.5px solid #cbc9c9;
}

.contact_detail {
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.footer_btn {
  width: fit-content;
  float: left;
  margin-right: auto;
  margin-top: 10px;
  padding: 0 30px;
}
.contact_detail .contact {
  margin: 5px;
}
.footer_site {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.footer_site p {
  padding: 0 10px;
  border-right: 1px solid #c9c9c9;
}
.footer_site p:first-child {
  padding-left: 0;
}

.footer_site p:last-child {
  border-right: none;
}
.socail_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 10px 0;
}
.socail_icon a svg {
  width: 32px;
  transition: all 0.3s ease;
  fill: #272727;
}
.socail_icon a:hover svg {
  fill: #3362b8;
}

.__Banner .slick-slider .slick-dots {
  bottom: 15px;
  left: 95%;
  width: 100%;
  transform: translate(-50%, -50%);
}

.__Banner .slick-dots li button {
  font-size: 13px;
  line-height: 15px;
  width: 20px;
  height: 20px;
  padding: 5px;
  color: #000;
}
.__Banner .slick-dots li button::before {
  display: none;
}
.slider_count {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider_count svg {
  margin: 0 7px;
  object-fit: contain;
  height: 60px;
  stroke-width: 0;
  fill: rgb(101 100 100 / 82%);
}
.currentslide span {
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
}
.currentslide {
  position: absolute;
  right: 0%;
  bottom: 2.5%;
}
.why_assure {
  background: #dbe7f9;
  padding: 80px 0;
}
.why_assure .circleicons span {
  background-color: #fff;
  box-shadow: 4px 4px 30px rgb(193 193 193);
}
.why_assure .circleicons span svg path {
  fill: var(--btn-color);
}
.why_assure .circleicons span:hover svg path {
  fill: #fff !important;
}
.why_assure .circleicons p {
  width: 80%;
  margin: auto;
  font-weight: 600;
  color: #4b4b4b;
}

.about_us_image img {
  height: auto;
}

.about_us_scn .title h2:after {
  left: 0;
  transform: none;
}
.about_us_scn {
  text-align: left;
  align-items: flex-start;
}
.abt_scn__ {
  width: 100%;
  display: flex;
  margin: 0 auto;
}

.about_us_cnt p {
  text-align: left;
  width: 95%;
  /* text-wrap: balance; */
}
.about_us_image img {
  border-radius: 50px;
  margin-bottom: 20px;
}

.__about__ .container,
._BreadCrums .container,
.header .container {
  min-width: 96%;
  width: 96%;
}

.upload_btn_sc {
  display: flex;
  gap: 12px;
}

.upload_btn {
  background: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  flex: 1 1;
  height: 50px;
}
.upload_btn.___cart {
  margin-left: 2px;
}

.upload_btn_cnt {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(210 226 244) !important;
}
.upload_btn_cnt:first-child > svg {
  width: 24px;
  height: auto;
}
.upload_btn_cnt.user svg {
  width: 25px;
}
.upload_btn_cnt svg {
  color: #000;
}
.upload_btn p {
  align-items: center;
  color: #000;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 18px;
  line-height: 16px;
  margin: 0;
  width: -webkit-min-content;
  width: min-content;
}
.__menus {
  margin-left: 4%;
  justify-content: flex-end;
  gap: 25px;
}
.mr-adj {
  margin: 0 6px;
}
._cerdit {
  width: 20% !important;
}
.footer_site {
  width: 80% !important;
}

.mdl-button--fab_flinger-container button {
  all: unset;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2b70bd;
}
.mdl-button--fab_flinger-container button svg path,
.mdl-button--fab_flinger-container button svg {
  width: 25px;
  height: 25px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  stroke: #fff;
}
.mdl-button--fab_flinger-container {
  position: fixed;
  bottom: 30px;
  right: -30%;
  transition: all linear 0.8s;
}
.mdl-button--fab_flinger-container.activated {
  right: 15px;
}

.mdl-button--fab_flinger-container.is-showing-options > button svg {
  -webkit-transition: -webkit-transform 0.1s linear;
  transition: transform 0.1s linear;
  transform: rotate(45deg);
}

.mdl-button--fab_flinger-container.is-showing-options
  .mdl-button--fab_flinger-options {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.mdl-button--fab_flinger-container.is-showing-options
  .mdl-button--fab_flinger-options
  button {
  display: block;
  -webkit-animation-name: enter;
  animation-name: enter;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-transform-origin: bottom center;
  -ms-transform-origin: bottom center;
  transform-origin: bottom center;
}

.mdl-button--fab_flinger-container.is-showing-options
  .mdl-button--fab_flinger-options
  button:nth-of-type(1) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.mdl-button--fab_flinger-container.is-showing-options
  .mdl-button--fab_flinger-options
  button:nth-of-type(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.mdl-button--fab_flinger-container.is-showing-options
  .mdl-button--fab_flinger-options
  button:nth-of-type(3) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.mdl-button--fab_flinger-container.is-showing-options
  .mdl-button--fab_flinger-options
  button:nth-of-type(4) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.mdl-button--fab_flinger-container.is-showing-options
  .mdl-button--fab_flinger-options
  button:nth-of-type(5) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.mdl-button--fab_flinger-container.is-showing-options
  .mdl-button--fab_flinger-options
  button:nth-of-type(6) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.mdl-button--fab_flinger-container .mdl-button--fab_flinger-options {
  position: absolute;
  bottom: 100%;
  margin-bottom: 10px;
}

.mdl-button--fab_flinger-container .mdl-button--fab_flinger-options button {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  display: none;
}

@-webkit-keyframes enter {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@keyframes enter {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
.mdl-ripple .is-animating {
  width: 160.392px;
  height: 160.392px;
  transform: translate(-50%, -50%) translate(17px, 26px);
}
.speed-dail {
  display: none;
  z-index: 10;
  position: relative;
}
.abt_title {
  display: none;
}
.abt_title .title h3 {
  letter-spacing: 0;
}

.BottomNavContainer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 64px;
  background: #fff;
  transition: bottom 0.5s;
  z-index: 1200;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 5px 0;
  box-shadow: 0px -3px 20px hsl(0deg 0% 57% / 15%);
  border-radius: 10px 10px 0 0;
}

.BottomNavItem {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  min-width: 54px;
  height: 100%;
  row-gap: 7px;
  padding: 0 5px;
  border-radius: 10px;
  width: 30vw;
}

.BottomNavItem.Reports {
  width: 40vw;
}

.BottomNavText {
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: center;
  color: #181818;
}

.BottomNavBarMobile_pill__wpKlP {
  position: absolute;
  border: none;
  border-bottom: 3px solid #0e3f6c;
  top: 0;
  width: 12px;
  border-radius: 0 0 4px 4px;
  transition: left 0.15s linear;
}

.BottomNavItem svg {
  width: 22px;
  height: 22px;
  color: #000;
  stroke: #000;
}
.mobile_upload_prs {
  display: none;
}
.enquireform._serach__ ._color {
  fill: rgb(96, 117, 134);
}
.enquireform label,
.rbt-input {
  font-size: 16px;
  text-indent: 15px;
}
._seasonalpack {
  width: 96%;
  margin: 0 auto;
}

/* .vedio_review_scn .slick-slide.slick-active:nth-child(1),
.vedio_review_scn .slick-slide.slick-active:nth-child(3),
.vedio_review_scn .slick-slide {
  transform: scale(0.9) translateX(3%);
  opacity: 0.9;
  z-index: 1;
  transition: all 0.3s ease-in;
} */

.vedio_review_scn .slick-slide.slick-active {
  transition: all 0.3s ease-in;
  opacity: 1;
  transform: scale(1) translateX(0);
}

.why_assure_cnt {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  row-gap: 50px;
  width: auto;
  padding-top: 10px;
  column-gap: 1%;
}
.why_assure_item {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 2rem;
  column-gap: 10px;
  width: 24%;
}
.why_assure_item span {
  width: 65px;
  height: 65px;
  background: #fff;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px #ededed;
}
.why_assure_text {
  font-size: 16px;
  color: #2e2e2e;
  text-align: left;
  text-wrap: balance;
  width: 15ch;
  line-height: 20px;
  letter-spacing: 0.2px;
}
.why_assure_item svg {
  width: 100%;
  height: 100%;
  padding: 9px;
  fill: #61787a;
}
.popular_packages {
  max-width: 100%;
}
.accordiancont h2 {
  font-size: 23px;
}

.accordiancont li {
  margin-bottom: 5px;
}

.accordiancont p {
  margin-bottom: 10px;
}

.accordiancont h3 {
  font-size: 19px;
  margin-bottom: 12px;
}

.accordiancont {
  display: flex;
  flex-direction: column;
  text-align: left;
}
._cart_cross svg {
  width: 20px;
}
.haeader_folow-us {
  line-height: 1;
  margin-top: 20px;
}
.menustn .contact_detail {
  line-height: 1.8;
}
/* .menslide:after {
  background-image: url(/public/bg\ layer.png);
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  width: 48%;
} */
._header_nav_.contact_detail {
  width: max-content !important;
}
/* YourComponentStyles.css */

/* Video Thumbnails */
.video_review_scene {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px;
}

.video_thumbnail {
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
}

.video_thumbnail i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 30px;
}

/* Custom Modal */
.your-custom-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.your-custom-modal.open {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
}

.modal_closer {
  display: flex;
  background-color: #ffffffbb;
  transition: ease-in all 0.3s;
  position: absolute;
  justify-content: flex-end;
  width: fit-content;
  margin-left: auto;
  top: 0;
  right: 0;
}
.modal_closer svg {
  width: 35px;
}
.modal_closer svg:hover {
  scale: 1.1;
}

.modal-body {
  position: relative;
}

.vedio_review_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Add more styles as needed */
.modal_content {
  width: 100%;
  height: 100%;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.menslide:before {
  content: "";
  background-image: url("./images/bg\ layer.png");
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
._header_nav_.contact_detail {
  z-index: 101;
}
.close_btn {
  background-color: #fafafa !important;
  margin-left: auto !important;
  min-width: 30px !important;
  width: 40px;
  height: 40px;
  border-radius: 0 !important;
}
.close_btn:hover {
  background-color: #fbfbfb !important;
  transition: ease-in all 0.3s !important;
  color: #000 !important;
}
.close_btn:hover svg {
  scale: 1.1;
}
.review-container {
  display: flex;
  align-items: flex-start;
  background: #fff;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
}

.review-stars {
  margin-right: 16px;
  max-width: 300px;
  /* padding: 0 40px; */
  background: #fff;
}

.review-star-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px 0px;
}

.review-star-row {
  /* height: 8px; */
  border-bottom: none;
  width: 250px;
  display: flex;
  flex-direction: row;
  /* gap: 5px; */
  align-items: center;
  /* margin-bottom: 5px; */
  background-color: transparent;
}

.review-star-count {
  width: 20px;
  color: rgb(112, 117, 122);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 20px;
  padding: 0;
  text-align: center;
  /* padding: 5px 0; */
  margin-right: 5px;
}

.review-star-bar {
  padding-left: 4px;
  position: relative;
  height: 8px;
  background-color: rgb(241, 243, 244);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  flex: 1;
  padding-left: 4px;
  border-radius: 5px;
}

.review-star-fill {
  position: absolute;
  top: 50%;
  left: 0;
  background-color: rgb(251, 188, 4);
  border-radius: 4px;
  height: 8px;
  transform: translateY(-50%);
}

.review-rating {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.review-rating-number {
  font-size: 56px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 64px;
  color: rgb(0, 0, 0);
}

.review-rating-stars {
  display: flex;
  margin-right: 8px;
}

.review-star {
  width: 14px;
  height: 14px;
  background-size: 14px 14px;
  background-image: url("./images/ic_star_rate_14.png");
  background-repeat: no-repeat;
  margin-right: 2px;
}

.review-rating-count {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 22px;
  color: rgb(112, 117, 122);
}
.google_review {
  width: 75%;
}

.testimonial_container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding-left: 7%;
  gap: 2%;
}
.google_review_sction {
  background: #fff;
  margin: 25px 0 15px;
  padding: 35px 15px;
  max-width: 400px;
}
.google_review_logo svg {
  width: 50px;
}
.google_review_logo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.google_review_logo p {
  margin: 0;
  font-size: 21px;
  font-weight: 600;
  color: #5a5a5a;
}
.review_btn {
  background: transparent;
  color: #000;
  border: 1.5px solid #969696;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  letter-spacing: 1px;
  text-transform: capitalize;
}
.review_btn a {
  color: #000;
}
.review_btn:hover a {
  color: #fff;
}
.review_btns {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}
.currentslide-review {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.googlereviewslider .slick-prev:before,
.googlereviewslider .slick-next:before {
  display: none;
  content: none;
}
.popular_packages_cnt li:hover a {
  font-weight: 600;
  color: #000;
}
.highlights._points p {
  transition: ease all 0.3s;
}
.highlights._points p:hover {
  border: solid 1px #e7e7e7;
  padding: 10px 30px;
  background: linear-gradient(80deg, #611ca2, #1198ca);
  color: #fff;
  box-shadow: 2px 2px 5px rgb(0 0 0 / 6%);
  transform: scale(1.03);
}

.fmenu ul li a::before {
  content: "";
  position: absolute;
  right: 0px;
  bottom: -2px;
  left: 0px;
  height: 1px;
  background: currentcolor;
  transform-origin: right center;
  transform: scaleX(0);
  transition: transform 0.83s cubic-bezier(0.33, 0, 0.13, 1) 0s;
}

.fmenu ul li a:hover::before {
  transform: scaleX(1);
  transform-origin: left center;
}
td.cart-detail-page-bin {
  width: 100px;
  text-align: center;
  cursor: pointer;
}
.BACKTOPACKAGE {
  position: fixed;
  overflow: hidden;
  height: 120px;
  width: 120px;
  background: rgb(122 122 122);
  border-radius: 50%;
  margin: 0 auto;
  bottom: 5vh;
  left: 5vh;
  z-index: 2;
  cursor: pointer;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BACKTOPACKAGE span {
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.8px;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}
.BACKTOPACKAGE:after {
  content: "";
  position: absolute;
  top: 50%;
  background: radial-gradient(
    circle,
    rgba(17, 152, 202, 1) 0%,
    rgba(97, 28, 162, 1) 100%
  );
  left: 50%;
  transform: translate3d(-50%, -50%, 0) scale(0);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
  transition: transform 0.45s cubic-bezier(0.4, 0, 0, 1);
}

.BACKTOPACKAGE:hover::after {
  transform: translate3d(-50%, -50%, 0) scale(1.05);
  opacity: 1;
}
.user_speed_dial svg {
  fill: #fff;
}
.cancer_accordion {
  column-count: 2;
}
.faq a {
  color: rgb(10 88 202);
}
._vedio_div {
  width: 30%;
}
.conditionshape:hover {
  transform: scale(1.01);
}
.packagedetail._test ul li.__tootip span {
  display: flex;
  justify-self: flex-start;
  align-items: center;
  width: 100%;
  text-wrap: balance;
}

li.__tootip svg {
  width: 22px;
}

.cart_dis,
.org_price,
.cart_itam_qnt {
  font-size: 14px;
  color: #424242;
}
.no_indenet .css-p0rm37 {
  text-indent: 0 !important;
  left: -12px !important;
}
.no_indenet .css-1wc848c-MuiFormHelperText-root {
  margin-left: 0 !important;
  color: #d32f2f !important;
}
.sub_total p {
  font-weight: 600;
}

.no_indenet label + .css-n70jm4-MuiInputBase-root-MuiInput-root-MuiSelect-root {
  margin-top: 9px;
}
.title-small {
  font-weight: 600;
  flex-basis: 100%;
  color: rgb(0 0 0 / 45%);
  text-transform: uppercase;
  width: 100%;
}
.title-small h1 {
  font-size: 16px;
  letter-spacing: 3.5px;
  font-weight: bolder;
  color: rgb(0 0 0 / 45%);
  margin: 8px auto 0;
}

.feature-card {
  background-color: #f0f8ff;
  border-radius: 0.5rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  width: 32%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  transition: transform 0.32s cubic-bezier(0.4, 0, 0, 1);
}

.feature-card:nth-child(2) {
  background-color: #f5f5dc;
}

.feature-card:nth-child(3) {
  background-color: #f0fff0;
}

.feature-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  z-index: 3;
}

.feature-card-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.feature-card-icon-fast svg {
  /* color: #87ceeb; */
  fill: #87ceeb;
}

.feature-card-icon-svg svg {
  fill: #ffdb23;
}

.feature-card-icon-delightful svg {
  fill: #90ee90;
}

h3.feature-card-title {
  margin-left: 0.75rem;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.feature-card-description {
  color: #343436;
  text-align: start;
  padding: 7px 5px 10px;
  letter-spacing: 1.2px;
  z-index: 3;
}

.feature-cnt {
  display: flex;
  gap: 1.5%;
  justify-content: space-between;
}
h3.feature-card-title {
  margin-left: 0.75rem;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2.4px;
  color: #2d2c2c;
  margin-bottom: 0;
}
._parmacy {
  gap: 55px;
}

img.barnd_partner_card {
  cursor: pointer;
  transition: 0.3s all ease;
  margin: 10px auto;
}

img.barnd_partner_card:hover {
  transform: scale(1.05);
}
.booble::after {
  content: "";
  height: 300px;
  width: 300px;
  z-index: 2;
  color: #fff;
  background: #98c5eb;
  position: absolute;
  right: -20%;
  top: -120%;
  transform: translate(50%, 50%) scale(0);
  border-radius: 50%;
  transition: transform 0.45s cubic-bezier(0.4, 0, 0, 1);
  opacity: 80%;
}

.booble.isHovered::after {
  transform: translate(0%, 80%) scale(1);
}
.booble.colour-1::after {
  background-color: #f7e9a1;
}
.booble.colour-2::after {
  background-color: #bef6be;
}
.about-whyus .why_assure {
  background: #eceded;
  padding: 60px 0;
}

.our-services {
  text-align: center;
  padding-top: 10px;
}

.services-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.service {
  margin: 15px;
  padding: 15px;
  border-radius: 5px;

  flex: calc(24.444% - 20px);
  width: auto;
  margin: 10px;
  padding: 20px;
  text-align: center;
}
.service h3.sevieces_heading {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2.2px;
  color: #262323;
  text-wrap: balance;
  width: 18ch;
  line-height: 1.6;
  margin: 0 auto;
  font-weight: bold;
}
.service p {
  letter-spacing: 0.8px;
  display: none;
}

.our-services .icon {
  width: 100px;
  height: 100px;
  margin: 0 auto 20px;
  background-size: cover;
  background-color: #c6e3fb;
  border-radius: 50%;
  padding: 20px;
  transition: all 0.45s cubic-bezier(0.4, 0, 0, 1);
}
p.subtitle {
  font-size: 21px;
}

.bg-gray {
  background-color: #fafafa;
}

.service:not(:first-child):hover .icon {
  background-color: #0599d2;
}
.service .icon svg path {
  transition: all 0.5s ease-in-out;
}
.service:not(:first-child):hover .icon svg path {
  fill: #fafafa;
}
.feature-card:hover {
  transform: scale(1.05);
}
.detaildescrp.Discount strong {
  position: relative;
  z-index: 0;
  font-size: 21px;
}
.detaildescrp.Discount strong:before {
  content: none;
  width: 100%;
  height: 40%;
  background: #efff00;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
li.ViewMore_footer a {
  color: #0066ff !important;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 12px;
}
._cart_test_detail button._cart_cross {
  width: auto;
  padding: 0;
}
.tabs {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.tab {
  border-right: 3px solid #8f8787;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 20px;
  list-style: none;
  width: 200px;
  position: relative;
  text-transform: uppercase;
  white-space: nowrap;
  color: #424242;
  font-weight: 600;
  text-align: center;
}
.tab:before {
  background: #000;
  bottom: -10px;
  content: "";
  height: 2px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  transition: all 0.5s ease;
  width: 0;
}
.tab.active:before,
.tab:hover:before {
  content: "";
  width: 50%;
}
.tab:last-child {
  border: none;
}
._pacakage_contet {
  width: fit-content;
}
._pacakage_contet h3 {
  line-height: 1.3;
}
._pacakage_contet {
  width: 25ch;
}
._pacakage_contet ul li {
  list-style: disc;
  margin-left: 15px;
}
._pacakage_contet a {
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 15px;
}

.read-more-container {
  position: relative;
}

.read-more-content {
  overflow: hidden;
  transition: max-height 0.9s ease-out;
}

.read-more-button {
  margin-top: 10px;
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
}

.read-more-button:hover {
  text-decoration: underline;
}
.assure_about p,
.about_us_cnt p {
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 28px;
}

li.MuiBreadcrumbs-li:last-child {
  color: #1493c8 !important;
}
.css-1ald77x,
.css-2fdkz6.MuiStepLabel-alternativeLabel,
.MuiBreadcrumbs-root.css-o3d33y {
  font-family: "AvertaStd", "sans-serif" !important;
}

/* Doctor deatails css?? */

.leaderships {
  float: left;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 80px;
}

.leadership2,
.leaderships {
  display: flex;
  /* justify-content: space-between; */
}

.leadership2 {
  align-items: center;
  /* -webkit-filter: grayscale(1);
  filter: grayscale(1); */
  margin: 30px 0 0;
  flex-direction: column;
  width: 300px;
}

.leadershipcont {
  float: left;
  width: 100%;
}
.abt_cont p {
  margin: 0;
}
.abt_cont ul {
  padding-left: 20px;
}

.abt_cont {
  padding-left: 25px;
  margin: 15px 0 25px 0;
}
.leadershipcont h2 {
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
}

.leadershipcont ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  text-transform: uppercase;
  width: 100%;
  padding-left: 0;
}

.leadershipcont ul li {
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 20px;
  margin: 2px 0;
  width: 100%;
}

.leadershipcont ul li:first-child {
  font-weight: 500;
}

.leadership2 .btn-3 span {
  font-size: 13px;
  line-height: 30px;
  padding: 0 15px;
}

.leadershipimg {
  float: left;
  overflow: hidden;
  position: relative;
  width: auto;
  background: #f5f5f5;
  border: 1px solid #ebebeb;
}

.leadership2 .leadershipcont {
  margin: 25px 0;
  width: 100%;
}

.leadership2:hover {
  -webkit-filter: none;
  filter: none;
}

.leadership2:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.leadershipimg img {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s ease;
  width: 350px;
  height: auto;
}

.abt_cont ul li {
  padding: 2px 0;
  letter-spacing: 0.4px;
}

.abt_doc_profile {
  background: #f7f7f7;
  padding: 50px 40px;
}

.doctor_name {
  padding: 60px 40px;
  background: linear-gradient(80deg, #6319a2, #0f9bcb);
}

.doctor_name .procont {
  color: #fff;
  display: flex;
  flex-direction: row;
}

.doctor_name .procont_name {
  padding: 0 30px;
}

.heading h3 {
  font-size: 19px;
  position: relative;
  display: block;
  padding: 0 20px;
  color: #fff;
  border-radius: 35px;
  background: linear-gradient(80deg, #6319a2, #0f9bcb);
  height: 50px;
  line-height: 50px;
  letter-spacing: 0.5px;
}

.abt_cont h5 {
  padding-left: 15px;
}

.abt_doc_profile ul li {
  list-style: disc;
}

.abt_doc_profile ul li::marker {
  color: #8635cf;
}

/* .heading h4:after {
    content: "";
    top: 50%;
    right: 0;
    position: absolute;
    width: 100%;
    height: 2px;
    display: block;
    background: #000;
}

.heading h3 strong:before {
    content: "";
    top: 0;
    right: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background: #f7f7f7;
    z-index: -1;
} */

.procont_name h2 {
  font-size: 38px;
  line-height: 1.5;
}

.procont_name {
  font-size: 14px;
  line-height: 21px;
}

.abt_cont h5 {
  font-size: 17px;
}
.our-founder {
  flex-direction: row;
}

.procont .doctor_name_img {
  width: auto;
  /* display: contents; */
  position: relative;
  z-index: 1;
  min-width: 250px;
}

.procont .doctor_name_img img {
  width: 100%;
  z-index: 1;
  position: relative;
}

.procont .doctor_name_img:before {
  height: 100%;
  width: 100%;
  content: "";
  position: absolute;
  top: -7%;
  left: -7%;
  background: #fff8f81a;
  z-index: 0;
}

.procont_name strong {
  padding-bottom: 5px;
}

@media (max-width: 1440px) {
  .btn-3 span {
    font-size: 13px;
    letter-spacing: 1px;
  }
}

@media (max-width: 1366px) {
  .leadershipcont h2 {
    font-size: 20px;
    line-height: 20px;
  }

  .leadershipcont ul li {
    font-size: 12px;
    letter-spacing: 1.5px;
    margin: 0;
  }

  .leadershipcont ul {
    margin-top: 5px;
  }

  .leadership2 .leadershipcont {
    margin: 15px 0 0;
  }
}

@media (max-width: 1040px) {
  .procont_name h2 {
    font-size: 30px;
  }

  .procont_name strong {
    padding-bottom: 12 px;
  }

  .heading h3 strong {
    font-size: 16px;
  }

  .btn-3 span {
    font-size: 12px;
  }

  .doctor_name .procont_name {
    padding: 0;
  }

  .abt_doc_profile,
  .doctor_name {
    padding: 40px 15px;
  }

  .doctor_name .procont {
    gap: 25px;
  }

  .abt_cont h5 {
    font-size: 15px;
    line-height: 1.3;
  }
}

@media (max-width: 780px) {
  .leaderships {
    gap: 50px;
  }
  .leadershipcont h2 {
    font-size: 17px;
    line-height: 24px;
    font-weight: 600;
  }
  .leadershipcont ul li {
    font-size: 12px;
    letter-spacing: 0.9px;
    margin: 0;
  }
}

@media (max-width: 680px) {
  .leadership2 {
    flex-wrap: wrap;
  }

  .leadershipcont {
    margin-top: 10px;
    width: 100%;
  }
  .leadershipcont ul li {
    font-size: 14px;
    letter-spacing: 1px;
    margin: 0;
  }
}

@media (max-width: 480px) {
  .leadership2 .leadershipcont {
    text-align: center;
  }
}

@media (max-width: 375px) {
  .leadershipcont h2 {
    font-size: 18px;
    letter-spacing: 0.5px;
  }

  .leadershipcont ul li {
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 16px;
  }

  .leadership2 {
    margin-top: 0;
  }
}

@media (max-width: 340px) {
  .leadershipcont h2 {
    font-size: 15px;
    line-height: 20px;
  }
}
b,
strong {
  font-weight: 600 !important;
}
.enquireform._serach__ {
  display: flex;
  flex-wrap: wrap;
}

.bg-gradient-image img {
  height: 1750px;
  width: 100%;
}
.abt_banner img {
  height: 50vh;
}
.about_us_scn {
  flex-wrap: nowrap;
}
.text_included {
  background: linear-gradient(335deg, #611ca2, #1198ca);
  border-radius: 12px;
  width: 22%;
  display: flex;
  position: absolute;
  top: -10px;
  right: 50%;
  padding: 5px 20px;
  width: auto;
  transform: translateX(50%);
}
.text_included span {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  color: #fff;
}
.sliderbox.landing_page_card {
  padding: 40px 15px 25px;
  gap: 18px;
}
.sliderbox.landing_page_card .packagename_test {
  height: 53px;
  text-overflow: ellipsis;
}
.sliderbox.landing_page_card .discountprice {
  background: linear-gradient(335deg, #611ca2, #1198ca);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 35px;
  margin: 0;
}
.sliderbox.landing_page_card .actualprice svg,
.sliderbox.landing_page_card .actualprice span {
  color: rgb(46 46 46 / 88%);
}
.sliderbox.landing_page_card .discountprice svg {
  height: 100%;
  width: auto;
}
.sliderbox.landing_page_card li.__tootip svg {
  width: 16px;
  color: #dbdbdb;
  opacity: 1;
}
.sliderbox.landing_page_card .packagename_test h2,
.packagename_test h3 {
  line-height: 28px;
}
.sliderbox.landing_page_card .packageprice {
  padding: 12px 0;
  gap: 12px;
  background: #d2e2f438;
}
.sliderbox.landing_page_card .discountprice svg path {
  color: #3068bb !important;
  fill: #3068bb !important;
  stroke: #3068bb !important;
}
.sliderbox.landing_page_card .textbtn a {
  font-weight: 600;
}

.landingPageBgImage {
  filter: hue-rotate(324deg);
  opacity: 25%;
  bottom: 0 !important;
}

.lp .why_assure {
  background: linear-gradient(179deg, #fff 0%, #d2e2f4 100%);
  padding-top: 100px;
}
.css-1086bdv-MuiPaper-root-MuiAccordion-root::before,
.css-1aj41gs::before {
  content: none !important;
}

.floating-button {
  position: fixed;
  bottom: 50%;
  right: -50%;
  background: linear-gradient(335deg, #611ca2, #1198ca);
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  transform: translateY(50%) rotate(0);
  transform-origin: right center;
  writing-mode: vertical-lr;
  padding: 1rem;
  transition: ease all 0.5s;
}
.floating-button.isVisible {
  transition: ease all 0.5s;
  right: 0;
}

.floating-button:hover {
  background: linear-gradient(335deg, #1198ca, #611ca2);
}
.lp.testimonial:before {
  background: rgb(210 226 244);
}
.landingPageFrom .page_equireheading__pocLQ article:after {
  content: "";
  background: #fff;
  position: absolute;
  width: 60%;
  height: 100%;
  left: 20%;
}
.landingPageFrom {
  width: 19%;
  position: absolute;
  right: -5%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #2d348e;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 40px 25px;
  backdrop-filter: blur(12px);
}
.landingPageFrom article span {
  text-align: center;
  font-size: 21px;
  letter-spacing: 2.5px;
  padding: 15px 0;
}
.landingPageFrom .call SVG path {
  fill: #fff !important;
}
.landingPageFrom button {
  background-color: #d42525;
  width: 100%;
}
.enquireform .rbt-menu.dropdown-menu.show {
  /* overflow: hidden !important; Enable vertical scrolling if content exceeds height */
}

.rbt-menu.dropdown-menu.show::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  z-index: 1;
}

.rbt-menu.dropdown-menu.show::-webkit-scrollbar-track {
  background: #f0f0f0; /* Background color of the track */
  border-radius: 4px; /* Rounded corners for the track */
}

.rbt-menu.dropdown-menu.show::-webkit-scrollbar-thumb {
  background-color: #87c2fd; /* Color of the thumb */
  border-radius: 4px; /* Rounded corners for the thumb */
}

.rbt-menu.dropdown-menu.show::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color when hovered */
}

.input-field_enquire .rbt-menu > .dropdown-item {
  font-size: 13px;
  padding: 5px 20px !important;
}
.input-field_enquire .dropdown-item:hover {
  background-color: #d7e9fc !important;
}
.input-field_enquire .dropdown-item.active,
.input-field_enquire .dropdown-item:active {
  background-color: #b9d8fa !important;
}
.sucees_mesg-message h3 {
  font-size: 30px;
  line-height: 1.3;
}
.input-error .rbt-input-main.form-control {
  border-color: #d32f2f;
}
.input-field_enquire .rbt-input-main.form-control:hover {
  border: 1px solid #0a0a0a !important; /* Change border color to blue on focus */
}
.input-field_enquire .rbt-input-main.form-control:focus {
  border: 1.5px solid #1976d2 !important; /* Change border color to blue on focus */
}
.input-field_enquire .dropdown-menu.show {
  border-radius: 0 0 20px 20px !important;
}
.input-field_enquire .rbt-input-main:focus:not(:placeholder-shown),
.input-field_enquire .rbt-input-main:focus:not(:placeholder-shown) {
  border-radius: 20px 20px 0 0 !important;
  border: 1px solid #c4c4c4 !important;
  border-bottom: none !important;
  background-color: #fff !important;
}
.bg-light-gray {
  background: rgb(250 250 252);
  flex-direction: column;
  z-index: 0;
}
.collectionForm button {
  letter-spacing: 1.6px;
}
.enquireform label,
.rbt-input {
  letter-spacing: 0.5px;
}
.bg-light-gray .enquireform .form-control,
.enquireform label,
.rbt-input {
  text-indent: 15px;
}
.bg-light-gray .css-feqhe6,
.css-feqhe6,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  text-indent: 15px !important;
  line-height: 1 !important;
}
.input-error .rbt-input-main.form-control,
.input-error .rbt-input-main.form-control::placeholder {
  color: #d32f2f;
}
.brandpartner .slick-prev:before,
.brandpartner .slick-next:before {
  color: #4a4848;
  font-size: 26px;
}
