/* Slide-right animation */
@keyframes slideRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.uniqueQrCodeContainer {
  position: fixed;
  bottom: 5%;
  right: 5%;
  background-color: #fff;
  z-index: 5;
  height: 240px;
  width: 200px;
  box-shadow: 2px 2px 24px 0 hsla(0, 0%, 55%, 0.322);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: slideRight 0.5s ease-out;
  transition: transform 0.5s ease-out;
}

.uniqueQrCodeContainer.slide-out {
  animation: slideOut 0.5s ease-out forwards;
}

.uniqueQrCodeCloseIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
}

.uniqueQrCodeCloseIcon svg {
  height: 20px;
  width: 20px;
}

.uniqueQrCodeTop p {
  text-align: center;
  font-size: 15px;
  color: #4d3b85;
  font-weight: 600;
  line-height: 21px;
  margin: 0 0 8px;
  letter-spacing: 0;
  text-transform: uppercase;
  margin: 0 15px 10px;
}

.uniqueQrCodeTop p span {
  color: #009ad0;
}
.QrCodeBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 12px 0 0;
}
.QrCodeBottom svg {
  width: 15px;
  cursor: pointer;
}

/* Hide QR Code on mobile screens */
@media (max-width: 768px) {
  .uniqueQrCodeContainer {
    display: none;
  }
}
