@media (max-width: 1700px) {
  .headersearchbox {
    width: 40%;
  }
  ._icon {
    width: 70px;
  }

  .headersearchbox .header_search .form-control {
    height: 50px !important;
  }
  .abt_banner img {
    width: 100%;
    height: auto;
    border-radius: 50px;
  }

  ._cart_test_detail h5 {
    font-size: 17px;
  }
  ._cart p {
    font-size: 15px;
  }
  .cta {
    margin-top: 30px;
  }
  .page_equireheading__sSxvP article {
    margin: 10px 0 !important;
  }

  .page_equireheading__sSxvP article:before {
    content: none !important;
    width: 0;
  }
  .__menus {
    margin-left: 0 !important;
    gap: 10px !important;
  }
  .enquireform .rbt-input-hint,
  .enquireform .form-control,
  .MuiInputBase-root.formbtn,
  .MuiInputBase-root.formbtn,
  .enquireform .MuiInputBase-root {
    line-height: 50px;
    height: 50px;
  }

  .whatsapp svg {
    width: 35px;
  }

  .call svg {
    width: 40px;
  }

  .h_form ._icon {
    height: 50px;
  }
}

@media (max-width: 1600px) {
  .title h1,
  .title h2,
  .title h3 {
    font-size: 26px;
    line-height: 30px;
  }
  .whatsapp p,
  .call p {
    margin: 0;
  }

  .footer_tabs ul li a {
    font-size: 12px;
  }

  .footer_tabs ul li {
    line-height: 1.4;
  }
  .page_inputmodified__uflvO label {
    top: 0 !important;
  }

  .sliderbox {
    padding: 25px 10px;
    margin: 30px auto;
    max-width: 320px;
  }

  .discountprice {
    line-height: 42px;
  }
  .about_us_cnt p {
    width: 100%;
  }
  ._seasonalpack {
    width: 100% !important;
  }
  .about_us_image {
    width: 650px;
  }
  .conditionshape h3 {
    font-size: 17px;
    line-height: 24px;
  }
}

@media (max-width: 1500px) {
  .package_card .packagename h3 {
    font-size: 16px;
    line-height: 24px;
  }
  header {
    margin-bottom: 0px;
  }
  .h_form {
    width: 100%;
  }
  .bg-light-gray .enquireform .form-control,
  .enquireform label,
  .rbt-input {
    font-size: 13px !important ;
    text-indent: 10px;
    line-height: 1 !important;
  }
  .bg-light-gray .css-1x5jdmq,
  .bg-light-gray .css-feqhe6,
  .css-feqhe6,
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    line-height: 1 !important;
    text-indent: 10px !important;
    font-size: 13px !important;
  }
  .sucees_mesg-message h3 {
    font-size: 29px;  
    line-height: 1.3;
  }
  .hemburgur {
    margin-left: 10px;
    margin-top: 5px;
  }
  .enquireform .MuiInputBase-root {
    margin-bottom: 10px;
  }
  .cta {
    margin-top: 15px;
  }
  .upload-files-container {
    background-color: #fbfbfb;
    width: 75%;
    padding: 30px;
  }
  .upload_btn_cnt {
    width: 45px;
    height: 45px;
  }
  .why_assure_item span {
    width: 55px;
    height: 55px;
  }
  .about_us_image {
    width: AUTO;
  }
  .about_us_scn {
    padding: 0px;
  }

  .about_us_image img {
    height: 100%;
  }

  .upload_btn_cnt:first-child svg,
  .upload_btn_cnt.user svg {
    width: 22px;
  }
  .nah_logo svg {
    width: 45px;
  }
  .page_equireheading__sSxvP article {
    margin: 0 !important;
  }
  .headersearchbox {
    width: 35%;
  }
  .__menus {
    gap: 15px;
  }

  .abt_bg_img {
    bottom: -32%;
  }

  .arrows .slick-next,
  .arrows .slick-prev {
    width: 70px;
    height: 70px;
  }

  .abt_banner img {
    object-fit: contain;
  }

  .textbtn {
    font-size: 13px;
  }

  .leftmenu .button {
    min-width: 100px;
    font-size: 15px;
  }

  .title {
    margin-bottom: 38px;
  }
  .vedio_cont img {
    object-fit: contain;
  }

  main {
    gap: 55px;
  }

  .googlereviewslider p {
    margin: 7px 0;
  }

  .baseon-rating {
    margin-bottom: 10px;
  }

  .iframe_scn iframe {
    width: 100%;
  }
  .container .title,
  ._acc_header article {
    font-size: 19px;
  }

  .title h1 span,
  .title h2 span,
  .title h3 span {
    font-size: 20px;
    font-size: 1.2;
  }
  .packagename_test h2,
  .packagename_test h3 {
    font-size: 17px;
    text-wrap: balance;
    vertical-align: middle;
    line-height: 24px;
  }
  .packagename_test {
    height: 62px;
  }

  .discountprice {
    font-size: 26px;
    width: 120px;
  }

  .highlights p {
    font-size: 14px;
  }

  .detaildescrp .button {
    letter-spacing: 0.5px;
  }

  .footercolumn,
  .footercolumn .fheading article,
  .slimenu ul li {
    font-size: 13px;
    line-height: 24px;
  }

  ._cart_test_detail span {
    font-size: 16px;
  }
  ._cart p {
    font-size: 14px;
    line-height: 20px;
  }

  .actualprice {
    font-size: 26px;
    line-height: 31px;
    padding: 10px 0;
  }

  .abt_banner img {
    object-fit: contain;
  }

  .sliderbox {
    padding: 25px 10px 15px;
    margin: 20px auto;
    max-width: 285px;
    gap: 10px;
  }

  .abt_bg_img {
    bottom: -32%;
  }

  .arrows .slick-next,
  .arrows .slick-prev {
    width: 70px;
    height: 70px;
  }

  .packagename p {
    font-size: 14px;
  }

  .packagedetail ul li {
    position: relative;
    font-size: 13px;
  }

  .package_card .sliderbox {
    max-width: 300px;
  }

  .packagename span {
    width: 120px;
    height: 120px;
  }

  .accordion__button {
    padding: 10px 20px;
  }
  ._user svg,
  .my-cart svg {
    width: 17px;
    margin-right: 4px;
  }

  .slimenu ul li {
    font-size: 13px;
    padding: 10px 8px;
  }

  .g-logo svg {
    width: 175px;
  }

  .package_card .packagename h3 {
    margin-bottom: 5px;
  }

  .package_card .packageprice {
    height: auto;
  }

  .button {
    font-size: 12px;
    letter-spacing: 1.2px;
  }
  .condition {
    margin-bottom: 20px;
  }

  .package_card .packagedetail {
    height: 100px;
  }
  .slider_count svg {
    height: 50px;
  }
  /* .package_card .packagename h3 {
  } */
  .our-founder-title h2 {
    font-size: 65px;
  }
  .bg-gradient-image.about_page {
    bottom: -140%;
  }
  .founder-details {
    max-height: 195px;
    overflow: hidden;
  }
  .founder-card-body h3 {
    font-size: 23px;
  }
  .effectheading.newheading {
    font-size: 45px;
  }
  .fmenu ul li {
    color: #000;
    font-size: 13px;
    letter-spacing: 1.2px;
    text-wrap: nowrap;
    line-height: 2;
  }
  .check_out_cnt h3 {
    font-size: 21px;
  }
  .faqcont h3 {
    font-size: inherit;
  }
  .arrows .slick-prev:before {
    left: 15%;
  }
  .arrows .slick-next:before {
    right: 15%;
  }
  .page_equireheading__pocLQ article:before,
  .page_equireheading__pocLQ article:after {
    content: none !important;
  }
  .about_us_scn .title {
    width: 90%;
  }
  .about_us_cnt {
    display: inline-block;
  }
  .landingPageFrom article span {
    font-size: 19px;
  }
  .landingPageFrom {
    width: 22%;
  }
}

@media (max-width: 1390px) {
  .enquireform .rbt-input-hint,
  .enquireform .form-control,
  .MuiInputBase-root.formbtn,
  .MuiInputBase-root.formbtn,
  .enquireform .MuiInputBase-root {
    line-height: 45px;
    height: 45px;
  }
  .upload_btn_cnt {
    width: 40px;
    height: 40px;
  }
  .__Banner {
    width: 94%;
    float: right;
  }
  .upload_btn_cnt:first-child svg,
  .upload_btn_cnt.user svg {
    width: 18px;
  }
  .upload_btn_sc .upload_btn:nth-child(1) .upload_btn_cnt svg {
    width: 20px;
  }
  ._icon svg {
    font-size: 25px;
  }
  ._icon {
    width: 60px;
  }
  .enquireform label,
  .rbt-input,
  .enquireform .rbt-input-hint,
  .enquireform .form-control {
    font-size: 13px;
  }
  .h_form ._icon {
    height: 45px;
  }
  header {
    min-height: 80px;
  }
  html,
  body {
    font-size: 15px;
    line-height: 24px;
  }

  .h_form {
    width: 98%;
  }
  ._home__banner .container {
    width: 96% !important;
    max-width: 100% !important;
  }
  .nah_logo svg {
    width: 45px;
  }
  .dis_price p {
    font-size: 20px;
  }

  .leftmenu .button {
    min-width: auto;
  }

  .gradient-layer {
    width: 15px;
  }

  .package_card .sliderbox {
    max-width: 280px;
  }

  .packagedetail ul::-webkit-scrollbar,
  .packagedetail::-webkit-scrollbar,
  ._acc_cnt ul li::-webkit-scrollbar,
  #searchTypeahead::-webkit-scrollbar {
    width: 5px;
  }

  .vedio_review .slick-prev:before,
  .vedio_review .slick-next:before,
  .googlereviewslider .slick-prev:before,
  .googlereviewslider .slick-next:before {
    height: 35px;
  }

  .page_equireheading__sSxvP article:after,
  .page_equireheading__sSxvP article:after,
  .page_equireheading__sSxvP article:before {
    content: none !important;
  }
  .discountprice,
  .actualprice {
    font-size: 23px;
  }

  .testimonialbox,
  .web-container,
  .quality {
    width: 90%;
  }

  .title h1,
  .title h2,
  .title h3 {
    font-size: 21px;
    line-height: 26px;
    letter-spacing: 1px;
  }

  .detailrow p {
    padding: 0 20px;
    font-size: 15px;
  }

  .detailrow.accordiancont ul li a,
  .detailrow .accordion__button a {
    font-size: 14px;
  }

  .footerrow {
    gap: auto;
    justify-content: space-between;
  }
  .services-container {
    justify-content: space-evenly;
  }

  ._cart_test_detail h5 {
    font-size: 16px;
    line-height: 1.5;
  }

  ._cart_cross {
    padding: 0 !important;
    margin: 0;
    font-size: 30px !important;
  }
  .app-time p {
    font-size: 17px;
  }

  .page_equireheading__sSxvP span {
    font-size: 18px !important;
  }

  .header,
  .actualprice {
    padding: 10px 0;
  }

  .packageprice {
    margin: 10px 0;
    gap: 10px;
  }
  .packagename span,
  .circleicons span {
    width: 120px;
    height: 120px;
    min-width: auto;
  }
  .circleicons svg {
    width: 60px;
  }
  .founder-details {
    max-height: 250px;
    min-height: 250px;
  }
  .title h1:after,
  .title h2:after,
  .title h3:after {
    height: 3px;
  }
  .about_us_cnt p {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
  .landingPageFrom {
    padding: 35px 25px;
  }
  .landingPageFrom article span {
    font-size: 17px;
  }
}

@media (max-width: 1300px) {
  ._home__banner .container {
    max-width: 96%;
  }

  .slimenu ul li {
    font-size: 12px;
    padding: 8px 5px;
  }

  .drag-file-area h3 {
    font-size: 18px;
    margin: 15px 0 0 0;
  }
  .our-founder-title h2 {
    font-size: 60px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 1.6px;
  }
  .bg-gradient-image.about_page {
    bottom: -155%;
  }
  .effectheading.newheading {
    font-size: 40px;
  }
  .plans .plan .plan-content {
    padding: 15px 30px;
  }
  .home_collection_data {
    padding: 40px 30px;
  }

  .drag-file-area label {
    font-size: 15px;
  }

  html,
  body {
    font-size: 15px !important;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  .leftmenu .button {
    font-size: 14px;
    height: 35px;
    line-height: 35px;
  }

  .my-cart_cnt {
    font-size: 10px;
    width: 15px;
    height: 15px;
  }

  ._acc_header article {
    font-size: 17px;
  }

  .sliderbox {
    max-width: 270px;
  }

  ._tooltip ._tooltip-text article {
    font-size: 15px;
    color: #000;
    line-height: 1;
  }

  ._tooltip ._tooltip-text p {
    font-size: 13px;
  }

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  .enquireform .rbt-input-hint,
  .enquireform .form-control,
  .enquireform .MuiInputBase-root label {
    font-size: 13px !important;
  }

  .conditionshape h3,
  .title h1 span,
  .title h2 span,
  .title h3 span {
    font-size: 18px;
    line-height: 1.2;
  }
  .packagename_test h2,
  .packagename h3,
  .packagename_test h3 {
    font-size: 16px;
    line-height: 1.3;
  }

  .discountprice,
  .actualprice {
    font-size: 20px;
  }

  .discountprice {
    line-height: 36px;
  }

  .actualprice svg,
  .discountprice svg {
    width: 21px;
    height: auto;
    left: 0;
  }

  .homecollection article a {
    font-size: 26px;
  }
  .upload_btn p {
    line-height: 15px;
    font-size: 12px;
    letter-spacing: 0;
  }

  .__menus,
  .upload_btn_sc {
    gap: 8px;
  }
  .mr-adj {
    margin: 0;
  }
  .upload-files-container {
    width: 75%;
    padding: 35px;
  }
  .prescription-form .input-field {
    margin-top: 10px;
    margin-right: 40px;
    padding: 0;
    gap: 10px;
  }
  .upload-prescription-form h4 {
    font-size: 18px;
  }
  ._vedio_div {
    width: 45%;
  }
  .ftrSitemap {
    flex-wrap: wrap;
  }

  .footercolumn {
    width: 30%;
  }

  .fheading article,
  .menslide .footercolumn .fheading article {
    margin: 0;
  }
  .footercolumn .fheading article {
    margin: 20px 0 5px 0;
  }
  .landingPageFrom article span {
    font-size: 17px;
    letter-spacing: 1.2px;
  }
}

@media (max-width: 1200px) {
  .__Banner {
    width: 100%;
  }
  .web-container {
    width: 98%;
  }

  .doctor_name .procont {
    flex-direction: column !important;
  }
  .sucees_mesg-message h3 {
    font-size: 26px;
    line-height: 1.3;
  }

  .doctor_name_img {
    max-width: 300px;
  }
  .packagename_test {
    height: 45px;
  }
  .packageprice {
    margin: 5px 0px;
    gap: 5px;
  }
  .logo {
    width: 145%;
  }

  .container {
    min-width: 94%;
    margin: auto;
  }

  .homecollection article a {
    font-size: 27px;
  }

  ._icon svg {
    width: 100%;
    font-size: 25px;
    font-weight: 100;
  }

  .packagename span {
    width: 100px;
    height: 100px;
  }
  .whatsapp p,
  .call p {
    display: none;
  }
  .upload_btn_cnt,
  .call svg,
  .whatsapp svg {
    width: 40px;
    height: 40px;
  }

  ._icon {
    width: 20%;
  }

  .arrows .slick-next,
  .arrows .slick-prev {
    width: 60px;
    height: 70px;
  }

  .abt_banner img {
    height: auto;
    border-radius: 35px;
  }

  body {
    font-size: 14px !important;
    line-height: 21px !important;
  }

  .arrows .slick-prev:before,
  .arrows .slick-next:before {
    background-size: contain;
  }

  .page_equireheading__sSxvP span {
    font-size: 16px !important;
  }

  .page_equireheading__sSxvP article {
    margin: 0 !important;
  }

  ._cart_cross {
    padding: 0 !important;
    margin: 0;
    font-size: 20px !important;
  }

  ._cart {
    width: 35%;
    right: -40% !important;
  }

  .slimenu ul li span,
  .leftmenu span span svg {
    width: 22px;
  }

  .footercolumn,
  .fheading article,
  .slimenu ul li {
    font-size: 12px;
    line-height: 24px;
  }

  .cart_in_slimenu {
    display: block;
    cursor: pointer;
  }

  .leftmenu {
    display: none;
  }
  .vedio_review .slick-prev:before,
  .vedio_review .slick-next:before,
  .googlereviewslider .slick-prev:before,
  .googlereviewslider .slick-next:before {
    height: 30px;
  }
  .effectheading.newheading {
    font-size: 33px;
  }
  .doctor_name .procont_name {
    padding: 0 !important;
    margin-top: 15px;
  }
  .headersearchbox {
    width: 30%;
  }
  .__menus,
  .upload_btn_sc,
  .upload_btn {
    gap: 5px;
  }

  .homecollection article a {
    font-size: 23px;
  }
  ._upload_hform {
    width: 48% !important;
    margin: 0 auto;
  }
  ._upload_hform span {
    display: none;
  }
  .enquireform {
    display: flex;
    flex-wrap: wrap;
  }
  .desktop_nav_search {
    width: 100%;
  }
  .h_form ._icon,
  .enquireform .rbt-input-hint,
  .enquireform button,
  .enquireform .form-control,
  .MuiInputBase-root.formbtn {
    max-height: 45px !important;
    height: 45px !important;
    line-height: 45px !important;
    font-size: 12.5px !important;
  }
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  .enquireform .rbt-input-hint,
  .enquireform .form-control,
  .enquireform .MuiInputBase-root label,
  label.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 12px !important;
    letter-spacing: 1px;
  }
  .googlereviewslider .revier_cnt {
    font-size: 14px;
  }
  .google_testimonial {
    padding: 20px;
  }
  .review-star-row {
    width: 170px;
  }
  .review-rating-number {
    font-size: 48px;
    line-height: 1;
  }
  .google_review_logo svg {
    width: 30px;
  }
  .review_btn {
    scale: 0.9;
  }
  .landingPageFrom article span {
    font-size: 16px;
    padding-top: 0;
    margin-top: 0;
    letter-spacing: 1.2px;
  }
}

@media (max-width: 1100px) {
  .about_us_scn .row {
    flex-direction: column-reverse;
  }
  .button {
    max-width: 300px;
    margin: auto;
    float: none;
    margin-top: 25px;
  }
  .landingPageFrom article span {
    font-size: 14px;
  }
  .packagename span {
    width: 80px;
    height: 80px;
  }
  .enquireform .MuiInputBase-root label {
    left: 0 !important;
    text-wrap: nowrap;
  }

  .footerrow {
    gap: 20px;
  }
  .dis_price p {
    font-size: 20px;
  }
  .our-founder {
    flex-direction: column;
  }

  .sliderbox {
    padding: 20 15px 10px;
  }

  footer {
    margin-top: 35px;
  }

  main {
    gap: 45px;
  }

  .packagename img {
    width: 50px;
  }
  .header_columns {
    width: 50%;
  }
  .header_columns .footercolumn {
    width: 45%;
  }
}

@media (max-width: 1040px) {
  .landingPageFrom {
    position: relative;
    transform: none;
    width: 100%;
    left: 0;
    bottom: 0;
  }
  .landingPageFrom button {
    background-color: #d42525;
    width: 40%;
    margin-top: 0;
  }
  .landingPageFrom .collectionForm {
    display: flex;
  }
}
@media (max-width: 991px) {
  .headersearchbox {
    width: 54%;
  }
  .sliderbox.landing_page_card .discountprice svg {
    height: 30px;
    width: 30px;
  }
  .sliderbox.landing_page_card .discountprice {
    font-size: 30px;
  }

  .upload_btn_sc {
    display: none;
  }
  .__Banner {
    flex-direction: column;
  }
  .speed-dail {
    display: block;
  }
  .footerrow {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 30px;
  }

  .title-small h1 {
    font-size: 14px;
  }

  .homecollection article a {
    line-height: 0;
    font-size: 25px;
  }

  .enquireform._serach__ {
    display: none;
  }

  .page_equireheading__sSxvP,
  .enquireform form {
    width: 100%;
    margin: 0 auto;
  }

  .p-80 {
    padding: 50px 0;
  }

  .bg-light-gray .collectionForm {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
  }

  .enquireform .css-feqhe6,
  .enquireform .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    width: 32% !important;
  }
  .bg-light-gray .collectionForm .Time_date.css-feqhe6,
  .bg-light-gray
    .collectionForm
    .Time_date.css-wb57ya-MuiFormControl-root-MuiTextField-root,
  .bg-light-gray .collectionForm .input-field_enquire {
    width: 48% !important;
  }
  .bg-light-gray {
    padding: 20px 0;
  }

  .plans .plan {
    width: 100%;
  }

  .plan.complete-plan {
    margin-top: 20px;
  }

  .plans .plan .plan-content .plan-details {
    width: 70%;
    display: inline-block;
  }

  .plans .plan input[type="radio"]:checked + .plan-content:after {
    top: 45%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
  .abt_title {
    display: block;
  }
  .about_us_scn .title {
    display: none;
  }
  .about_us_cnt {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .about_us_cnt p {
    width: auto;
    text-wrap: balance;
  }
  .about_us_scn {
    width: 100%;
    text-align: center;
    left: 0;
    top: 0;
    align-items: flex-start;
    margin: 0 auto;
  }
  .about_us_image img {
    border-radius: 0;
    padding-top: 10px;
  }
  .bg-gradient-image {
    bottom: -60%;
    z-index: -1;
  }
  .why_assure_item {
    width: 33%;
  }
  .why_assure_cnt {
    justify-content: center;
    row-gap: 25px;
  }
  .logo {
    width: 115%;
  }
  .fixed .logo {
    width: 100%;
  }
  .footer_btn {
    font-size: 11px;
  }
}

@media (max-width: 900px) {
  .google_review_sction {
    display: none;
  }
  .floating-button {
    display: none;
  }
  .__menus {
    gap: 25px !important;
  }
  .google_review {
    width: 95%;
  }
  .dis_price p {
    font-size: 20px;
  }
  .feature-cnt {
    flex-direction: column;
  }
  .feature-card {
    width: 80%;
    margin: 10px auto;
  }
  ._parmacy {
    gap: 45px;
  }
}
@media (max-width: 780px) {
  .h_form {
    width: 90%;
    margin: 0 auto;
  }
  .menslide {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: -100%;
    background-color: #dae8ec;
  }
  .why_assure_item {
    width: 48%;
  }

  .slick-slider .slick-dots li button:before {
    font-size: 8px;
  }

  .slick-slider .slick-dots li.slick-active button:before {
    font-size: 10px;
  }

  .slick-dots li {
    width: 16px !important;
  }

  .headersearchbox {
    width: 85%;
  }

  .homecollection span,
  .homecollection article,
  .nah_logo {
    display: none;
  }
  ._vedio_div,
  .our_diff_item .circleicons {
    width: 100%;
  }

  .fixed .menslide.active,
  .menslide.active {
    top: 0px;
    padding-top: 10vh;
  }
  .slider_count svg {
    height: 40px;
  }

  .logo {
    position: relative;
    z-index: 10;
  }
  .hemburgur {
    z-index: 10;
  }
  .headerstrip {
    display: none;
  }
  .detailtitle h2 {
    font-size: 16px;
  }
  .highlights.whoIs p,
  .highlights.turn_around p,
  .highlights.pre_test p {
    border: none !important;
    padding: 0 25px !important;
  }
  .detailrow p,
  .detaildescrp .packageprice {
    padding: 0 25px !important;
  }
  .detailrow .row {
    gap: 10px;
  }
  .plans .plan .plan-content .plan-details {
    width: 60%;
    display: inline-block;
  }
  .plans_selection {
    align-items: start;
    flex-direction: column-reverse;
    gap: 20px;
  }
  .plans .plan .plan-content {
    padding: 20px 30px;
  }
  .hcd_date_seection {
    flex-wrap: wrap;
  }
  .our-founder-title {
    width: 90%;
    justify-content: flex-start;
  }
  .our-founder-title h2 {
    font-size: 40px;
  }
  .overview p {
    width: 55ch;
    margin: 0 auto;
    text-align: center;
  }
  ._cart {
    width: 100%;
    right: -105% !important;
  }

  .footer_site {
    width: 80% !important;
  }
  ._cerdit {
    width: 20% !important;
  }
  .footer_site p {
    padding: 0 6px;
  }
  .footergray p {
    font-size: 11px;
  }
  .footer_btn {
    padding: 0 15px;
  }
  .__menus {
    justify-content: space-between;
  }
  .popular_packages_cnt li {
    width: 30%;
  }
  .vedio_thumbnail {
    margin: auto;
    width: 100%;
  }
  .vedio_cont img {
    width: 100%;
    aspect-ratio: auto;
    height: auto;
  }
}

@media (max-width: 680px) {
  .why_assure {
    padding: 50px 0;
  }
  .landingPageFrom .collectionForm {
    flex-direction: column;
  }
  .landingPageFrom button,
  .landingPageFrom
    .enquireform
    .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    margin: 0 auto 15px;
    width: 100% !important;
    max-width: 100%;
  }
  .landingPageFrom {
    margin-inline: 1.5%;
    width: 97%;
  }
  .sucees_mesg-message h3 {
    font-size: 23px;
    line-height: 1.3;
  }
  .why_assure_text {
    width: auto;
    text-align: center;
  }
  .brandpartner.arrows .slick-next:before,
  .brandpartner.arrows .slick-prev:before {
    top: 0;
  }
  .brandpartner.arrows .slick-next,
  .brandpartner.arrows .slick-prev {
    width: 35px;
    height: 35px;
    z-index: 1;
  }
  .brandpartner.arrows .slick-slider .slick-dots li button:before {
    color: #7e7e7e;
  }
  .brandpartner.arrows .slick-slider .slick-dots li.slick-active button:before {
    color: var(--secondary-color);
  }

  .why_assure_item {
    flex-direction: column;
    gap: 10px;
  }
  td.cart-detail-page-bin {
    width: auto;
    text-align: right;
    cursor: pointer;
  }
  ._cart {
    height: 100dvh;
    inset: 1;
  }

  table {
    border: 0;
  }
  ._parmacy {
    gap: 40px;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  ._pacakage_contet {
    width: 100%;
  }
  ._pacakage_contet h3 {
    margin: 10px 0;
  }
  .privacy_pol_cnt h3,
  .contentbox h2,
  .contentbox h3 {
    font-size: 19px;
    line-height: 1.3;
  }

  table tr {
    /* border-bottom: 3px solid #eeeeee; */
    display: block;
    margin-bottom: 15px;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 15px;
    text-align: right;
    padding: 20px 15px;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 13px;
    width: 45%;
    text-align: left;
  }
  .css-imxc6v-MuiBadge-badge {
    font-size: 11px !important;
    padding: 0 !important;
  }

  .drag-file-area input {
    object-fit: contain;
    height: 100%;
    width: auto;
  }

  .footer_site {
    width: 90% !important;
  }
  .footergray p {
    text-align: left;
  }
  .file-block {
    display: none;
  }

  table td:last-child {
    border-bottom: 0;
  }
  .bread_crums {
    margin: 5px 0;
  }
  .checkout_input {
    gap: 15px;
  }
  .upload-icon svg {
    max-width: 120px;
  }

  .highlights.whoIs p,
  .highlights.turn_around p,
  .highlights.pre_test p,
  .detailrow p {
    padding-inline: 25px !important;
  }

  .our-founder-content {
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .bg-gradient-image.about_page {
    bottom: -520%;
  }
  .cancer_accordion {
    column-count: 1;
  }

  .our-founder-title h2 {
    font-size: 35px;
  }
  .overview p {
    width: 80%;
  }
  .leaderships {
    gap: 17px !important;
    flex-wrap: wrap;
  }
  .title h2 {
    padding-bottom: 8px;
  }
  .footer_bottom {
    gap: 15px;
  }
  .popular_packages_cnt li {
    width: 50%;
  }
  .upload-files-container {
    width: 100%;
    padding: 0 10px;
    height: 100%;
    border-radius: 0;
  }
  .prescription-form .input-field {
    margin: 0;
  }
  .prescription-form {
    width: 98%;
    flex-direction: column;
  }
  .upload-prescription-form {
    width: 100%;
    margin: 0 auto;
  }
  .mobile_upload_prs {
    display: block;
  }
  .desktop_upload_pres {
    display: none;
  }
  .drag-file-area {
    width: 100%;
    text-align: center;
    background: #f1f1f1;
    overflow: hidden;
    margin: 25px 0;
  }
  ._cross {
    color: #000;
    right: 0;
  }
  .founder-card,
  .founder-details {
    height: auto;
    max-height: none;
    min-height: 100px;
  }
  .abt_banner img {
    border-radius: 0;
  }
  .leaderships,
  .__menus {
    gap: 15px;
  }
  .procont .doctor_name_img img {
    width: auto !important;
  }
  .procont_name h2 {
    margin-bottom: 8px;
  }
}

@media (max-width: 580px) {
  main {
    gap: 45px;
  }

  .header_search {
    display: block;
  }
  .h_form {
    width: 98%;
    margin-top: 20px;
  }
  .footergray p {
    text-align: center;
  }
  .sucees_mesg-message h3 {
    font-size: 20px;
    line-height: 1.2;
  }

  ._cart_cross svg {
    width: 20px;
  }
  .feature-card {
    width: 90%;
    margin: 10px auto;
  }
  .h_form .button {
    max-width: 100% !important;
    width: 100%;
    margin-inline: 0;
  }
  .barnd_partner_card {
    width: 70%;
    height: auto;
    margin: 0 auto;
    object-fit: contain;
  }
  .cart_container {
    padding: 0 10px;
    background-color: #fff;
  }
  ._cart_list {
    background-color: #fbfbfb;
    padding: 10px;
  }
  ._cart_list {
    margin: 0;
    margin-top: 16px;
  }
  ._cart_test li::before {
    bottom: -5px;
    background-color: rgb(179 179 179 / 50%);
  }
  .slot-time-not {
    margin: 0 !important;
  }
  .popular_packages_cnt li {
    width: 100%;
  }

  .cnt_shp_btn {
    margin-top: 10px;
  }

  .packagename h3 {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 15px;
    text-wrap: balance;
  }

  .navbar_item {
    display: none !important;
  }

  .title {
    margin-bottom: 25px;
  }

  .seasonalpack .sliderbox {
    margin-top: 10px;
  }

  .detaildescrp p strong {
    font-weight: 400;
  }

  .actualprice {
    font-size: 24px;
    margin-left: 15px;
  }

  .discountprice {
    font-size: 24px;
    height: 45px;
  }

  .vedio_review .slick-prev:before,
  .vedio_review .slick-next:before,
  .googlereviewslider .slick-prev:before,
  .googlereviewslider .slick-next:before {
    height: 30px;
  }

  .gradient-layer {
    display: none;
  }

  ._acc_header article {
    font-size: 15px;
    text-align: center;
  }
  ._icon svg {
    height: 30px;
  }
  .enquireform .MuiInputBase-root,
  .h_form ._icon,
  .h_form .MuiInputBase-root.formbtn,
  .enquireform .form-control {
    max-height: 50px;
  }
  .enquireform .rbt-input-hint,
  .enquireform .form-control,
  .MuiInputBase-root.formbtn,
  .MuiInputBase-root.formbtn,
  .enquireform .MuiInputBase-root {
    height: 50px;
    line-height: 50px;
  }
  .footergray {
    padding: 30px 0 20px 0;
  }

  .vedio_review .slick-prev {
    right: 21%;
    left: inherit;
  }
  .button {
    min-width: 100%;
  }
  header {
    min-height: 60px;
  }

  .bread_crums .MuiBreadcrumbs-separator,
  .bread_crums span.MuiChip-label {
    margin: 0;
    padding-inline: 2px;
  }

  .bread_crums .MuiBreadcrumbs-li {
    font-size: 11px;
  }

  .bread_crums .MuiSvgIcon-root {
    font-size: 15px;
    margin-right: 2px;
  }

  .bread_crums .MuiBreadcrumbs-separator,
  .bread_crums span.MuiChip-label,
  .MuiChip-label,
  .bread_crums span.MuiChip-label:hover,
  .css-1mm5wr7-MuiChip-root,
  .css-1mm5wr7-MuiChip-root:hover,
  .css-1mm5wr7-MuiChip-root:focus {
    background-color: transparent !important;
    user-select: none;
    cursor: pointer;
  }

  .logo {
    width: 120px;
  }

  .fixed .logo {
    width: 100px;
  }
  .hemburgur {
    height: 35px;
    outline: 0;
    position: relative;
    right: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    width: 35px;
  }

  .hemburgur span:nth-child(2) {
    width: 50%;
  }
  .hemburgur span:nth-child(1) {
    top: 4px;
  }
  .hemburgur span:nth-child(3) {
    top: 24px;
  }

  .hemburgur span {
    width: 100%;
    height: 1.3px;
    border-radius: 2px;
  }

  .hemburgur span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background: #7e7e7e;
    transition: 0.5s ease-in-out;
    float: left;
    position: absolute;
  }

  .hemburgur.active span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 15px;
    left: 5px;
    width: 80%;
  }

  .hemburgur.active span:nth-child(2) {
    opacity: 0;
  }

  .hemburgur.active span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 15px;
    left: 5px;
    width: 80%;
  }

  .search_icon {
    width: 22px;
  }
  .No_Data svg {
    width: 80%;
  }
  .mobile_nav_search svg {
    font-size: 24px;
    color: #7e7e7e;
  }

  .swiperslider img {
    height: auto;
    margin-left: auto;
    float: right;
    width: 100%;
    object-fit: contain;
  }

  .web-container {
    width: 98%;
  }
  .mobile_nav_search {
    gap: 14px !important;
  }

  .btn_main {
    max-width: 130px;
    margin: auto;
  }

  .mobile_nav_search .cross,
  .mobile_search_cnt ._icon svg,
  .hemburgur {
    width: 25px;
    height: 30px;
  }
  .hemburgur {
    width: 22px;
  }
  .menslide.active {
    padding-top: 60px !important;
    z-index: 9;
  }
  .header_columns {
    gap: 18px;
  }

  .sliderbox {
    width: 98%;
    max-width: 280px;
    padding: 20px 10px;
  }
  .menustn {
    height: 40px;
  }
  .enquireform .css-feqhe6,
  .bg-light-gray .collectionForm .Time_date.css-feqhe6,
  .enquireform .css-wb57ya-MuiFormControl-root-MuiTextField-root,
  .bg-light-gray
    .collectionForm
    .Time_date.css-wb57ya-MuiFormControl-root-MuiTextField-root,
  .bg-light-gray .collectionForm .input-field_enquire {
    width: 100% !important;
  }
  .menslide .footercolumn {
    width: 100%;
  }
  #organs .sliderbox {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 40px;
  }

  #organs .packagename span {
    height: 120px;
    width: 120px;
  }

  .googlereviewslider {
    margin: 0 auto;
  }

  .arrows .slick-next,
  .arrows .slick-prev {
    width: 50px;
    height: 50px;
    z-index: 1;
  }

  .mobile_search_cnt {
    height: 100vh;
    width: 100vw;
    z-index: 11;
    padding: 10px 10px;
    top: -100vh;
    transition: 0.4s all;
  }

  .mobile_search_cnt .form-control {
    border: none;
    border-bottom: 1px solid #e5e5e5;
    border-radius: 0;
    padding-left: 35px;
    padding-right: 10px;
    height: 50px;
  }

  .googlereviewslider p:first-child {
    line-height: 1.6;
    font-size: 15px;
    margin: 0 auto;
    letter-spacing: 1px;
    text-wrap: balance;
    width: 95%;
  }
  .mobile_nav_search .cross {
    margin-top: 5px;
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 10;
  }

  .mobile_searchbar,
  .mobile_search_cnt .header_search {
    width: 100%;
    margin-left: auto;
  }

  .mobile_search_cnt .header_search ._icon {
    color: black;
    background: none;
    width: max-content;
  }

  .mobile_nav_search ._icon {
    border-radius: 0 8px 8px 0;
  }

  .mobile_searchbar .dropdowntab {
    display: none;
  }

  .mobile_searchbar .dropdowntab.dropdowntab_for_header {
    box-shadow: none;
    display: block;
    position: relative;
  }

  .mobile_searchbar .dropdowntab .listdata {
    max-height: 100%;
  }

  #organs .slick-prev {
    left: 0px !important;
  }

  #organs .slick-next {
    right: 0px !important;
  }

  .slick-slider .slick-dots li button:before {
    color: #fff;
  }

  .accordion {
    width: 100%;
    margin: 0 auto;
  }

  .detaildescrp .accordion__button {
    font-size: 14px;
    letter-spacing: 1px;
  }

  .title h1:after,
  .title h2:after,
  .title h3:after {
    width: 30px;
    height: 2px;
  }

  .abt_banner img {
    height: auto;
    width: 100%;
  }

  .highlights {
    margin-left: 7%;
  }

  .detailrow .highlights p {
    padding: 10px 20px !important;
    border: solid 1px #d8dee7;
    border-radius: 35px;
  }

  .contentbox {
    width: 95%;
    margin: 0 AUTO;
  }

  .privacy_pol_cnt h3,
  .contentbox h3 {
    font-size: 18px;
    font-weight: 600;
  }

  .enquireform.headersearchbox,
  .home_banner .slick-dots {
    display: none !important;
  }

  .conditionshape h3 {
    font-size: 17px;
  }
  .condition {
    margin-bottom: 15px;
  }

  .accordion__button {
    border-radius: 30px;
  }

  .p-80 {
    padding: 30px 0;
  }

  .bg-gradient-image img {
    object-fit: cover;
  }

  .sliderbox {
    box-shadow: 0px 4px 7px rgb(217 229 236);
  }
  .packagename_test h2,
  .packagename_test,
  .packagename_test h3 {
    height: auto;
  }

  ._cart {
    width: 100%;
    right: 0 !important;
    top: -110% !important;
  }

  .__visible._cart {
    top: -0% !important;
  }

  ._cart_test_detail h5 {
    font-size: 17px;
  }

  .pad-0,
  ._cart_cross {
    padding: 0;
  }

  .svgwidthline,
  .hsection {
    display: none;
  }
  .banner_slide {
    gap: 20px;
  }
  .button {
    min-width: 125px;
  }
  .h_form ._icon,
  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root,
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  .enquireform .rbt-input-hint,
  .enquireform button,
  .enquireform .form-control,
  .MuiInputBase-root.formbtn {
    max-height: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
    font-size: 12.5px !important;
  }
  .cookies_consent {
    width: 100%;
    /* margin: 0 1.5%; */
    right: 0;
  }
  .cookies_consent.IsCookies {
    bottom: 0;
  }
  .plans .plan .plan-content img {
    margin-bottom: 20px;
    height: 56px;
    -webkit-transition: height 0.4s;
    -o-transition: height 0.4s;
    transition: height 0.4s;
  }

  .plans .plan input[type="radio"]:checked + .plan-content:after {
    top: 20px;
    right: 10px;
  }
  .plans .plan .plan-content .plan-details {
    width: 100%;
  }

  .plans .plan .plan-content {
    padding: 20px 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
  .home_collection_data {
    padding: 20px 10px;
  }
  .hcd_date_seection {
    margin-left: 0;
  }
  ._acc_header article {
    font-size: 13px;
  }
  .highlights.whoIs p,
  .highlights.turn_around p,
  .highlights.pre_test p {
    padding-inline: 0 !important;
  }
  .overview p {
    width: 95%;
  }
  .effectheading.newheading {
    font-size: 27px;
  }
  .radio-btn {
    width: 95px;
    margin: 6px;
  }
  .footer_site {
    flex-wrap: wrap;
  }
  .__menus {
    justify-content: flex-end;
  }
  .contact_detail {
    gap: 5px;
  }
  .footercolumn {
    width: 40%;
  }
  .mdl-button--fab_flinger-container.activated {
    position: fixed;
    bottom: 35px;
    right: 35px;
  }
  .package_card .packagename h3 {
    height: 65px;
    overflow-y: hidden;
  }
  .notfound {
    text-align: center;
    width: 85%;
  }
  .title-small h1 {
    font-size: 12px;
    letter-spacing: 2px;
    margin-bottom: 10px;
  }
  .title-small {
    margin-bottom: 10px;
  }
  .about-assure-text {
    width: 100%;
  }
  .landingPageFrom .enquireform {
    display: flex !important;
  }
  .footer_btn {
    font-size: 11px;
    width: 90%;
  }
}

@media (max-width: 480px) {
  .testimonial_container {
    padding: 0;
    margin: 0 auto;
  }
  .landingPageFrom button,
  .landingPageFrom
    .enquireform
    .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    margin: 0 auto 10px;
  }

  .No_Data svg {
    width: 90%;
  }
  .sucees_mesg-message h3 {
    font-size: 18 px;
    line-height: 1.2;
  }
  .about_us_cnt p {
    text-align: center;
  }
  ._parmacy {
    gap: 30px;
  }
  .service {
    width: 100%;
  }
  .service h3.sevieces_heading {
    text-wrap: inherit;
    width: 80%;
  }
  .services-container {
    flex-direction: column;
  }
  .footercolumn {
    width: 100%;
  }
  .feature-card {
    width: 95%;
    margin: 10px auto;
  }
  .doctor_name h3 {
    margin-bottom: 10px;
  }

  .logo {
    width: 100px;
  }
  .fixed .logo {
    width: 90px;
  }
  .about_us_scn {
    padding: 0;
  }
  .mdl-button--fab_flinger-container.activated {
    position: fixed;
    bottom: 15px;
    right: 15px;
  }
  .enquireform .button {
    max-width: 100%;
    margin-inline: 0;
  }
  .bg-light-gray .enquireform .form-control,
  .enquireform label,
  .rbt-input {
    line-height: 1.4 !important;
  }
  .page_equireheading__sSxvP article {
    padding: 0 0 15px !important ;
  }
  ._cerdit,
  .footer_site {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .__menus {
    gap: 20px !important;
  }
  .abt_banner {
    margin: 0 0 10px;
  }
  .bg-gradient-image.about_page {
    bottom: -420%;
  }
  .modal_body video {
    width: 90%;
    height: auto;
    aspect-ratio: 4/7;
    margin: 0 auto;
  }
  .modal_body {
    text-align: center;
  }
  .fmenu ul li {
    text-wrap: balance;
  }
}

@media (max-width: 360px) {
  .title h1,
  .title h2,
  .title h3 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
  .tab {
    font-size: 14px;
    letter-spacing: 1px;
  }
  .all-test .sliderbox {
    margin-bottom: 25px;
    width: 100%;
    max-width: 100%;
  }
  .condition {
    width: 98%;
  }
  .popular_packages_cnt li {
    width: 100%;
  }
  .barnd_partner_card {
    width: 80%;
    height: auto;
    margin: 0 auto;
    object-fit: contain;
  }
}
